import { Component, Inject, Injector } from '@angular/core';
import { FormControl, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Portfolio } from 'src/app/model/portfolio';
import { EquityService } from 'src/app/services/equity.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import {Option} from 'src/app/model/Option';
import { max } from 'rxjs';

@Component({
  selector: 'app-add-portfolio-dialog',
  templateUrl: './add-portfolio-dialog.component.html',
  styleUrls: ['./add-portfolio-dialog.component.css']
})
export class AddPortfolioDialogComponent {
  
  portfolioTypeSel!:any;
  portfolioProSel!:any;
  triggerPrice!:any;
  trigerPriceDisable!:boolean;


  constructor(private router:Router, private dialog:MatDialog,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(MAT_DIALOG_DATA)  public data: { 
    title: string, message: string,
    orderId:number,
    portfolio:Portfolio,
    action:string,
    portfolioType: Option[],
    portfolioPro: Option[],
    defaultPrice: number,
    transactionType:string,
    orderStatus:string   
  }, 
  private equityService:EquityService,private userService:UserService,
  ){ 
   
    if(data.action == 'rename'){
      console.log("rename::this.data.portfolio.portfolioType::"+this.data.portfolio.portfolioType
      +"portfolioPro"+this.data.portfolio.portfolioPro);
      this.portfolioTypeSel = this.data.portfolio.portfolioType;
      this.portfolioProSel = this.data.portfolio.portfolioPro;
      this.triggerPrice = this.data.portfolio.quotedPrice;
      this.portfolioDialogForm.get('portfolioName')?.setValue(this.data.portfolio.portfolioName);
      this.portfolioDialogForm.get('category')?.setValue(this.data.portfolio.category);
      this.portfolioDialogForm.get('quotedPrice')?.setValue(this.data.portfolio.quotedPrice);
      this.portfolioDialogForm.get('description')?.setValue(this.data.portfolio.description);
    }else{
      this.portfolioTypeSel = "private";
      this.portfolioProSel = "no";
    }
    // this.onChangePortType();
   }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  portfolio:Portfolio = new Portfolio();
  

  portfolioDialogForm = new FormGroup(
    {
      portfolioName :new FormControl('',[Validators.required]),
      description :new FormControl('',[Validators.required]),
      category:new FormControl('',[Validators.required]),
      quotedPrice:new FormControl(0,[Validators.required,Validators.pattern("^[0-9]+(\\.[0-9]+)*$"), 
                          Validators.minLength(0),Validators.maxLength(10)])      
    }
  );

  onChangePortType(){
    console.log(this.portfolioTypeSel);
    
    if(this.portfolioTypeSel == "private"){
      this.data.portfolioPro[0].disabled = true;
      this.data.portfolioPro[1].checked = true;
    }else{
      this.data.portfolioPro[0].disabled = false;
    }     
  }

  onChangePortfolioTypeSel(){
      if(this.portfolioTypeSel === 'private'){
          this.portfolioDialogForm.get("quotedPrice")?.setValue(0.0);
      }
  }

  onChangePortfolioProSel(){
    if(this.portfolioProSel === 'no'){
      this.portfolioDialogForm.get("quotedPrice")?.setValue(0.0);
  }
  }

  onChangePortProType(){
    console.log(this.portfolioProSel);
    if(this.portfolioProSel == "yes"){
      this.trigerPriceDisable == true;
    }else{
      this.trigerPriceDisable == false;
    }
   
  }

  onPortfolioSubmit(){

    this.portfolio.agentId = Number(sessionStorage.getItem('userId'));
    this.portfolio.portfolioName = this.portfolioDialogForm.value.portfolioName != undefined ? this.portfolioDialogForm.value.portfolioName:'';
    this.portfolio.portfolioValue = this.portfolioDialogForm.value.portfolioName != undefined ? this.portfolioDialogForm.value.portfolioName:'';
    this.portfolio.portfolioType = this.portfolioTypeSel;
    this.portfolio.portfolioPro = this.portfolioProSel;
    this.portfolio.quotedPrice = this.portfolioDialogForm.value.quotedPrice != undefined ? this.portfolioDialogForm.value.quotedPrice:0;
    this.portfolio.description = this.portfolioDialogForm.value.description != undefined ? this.portfolioDialogForm.value.description:'';
    this.portfolio.category = this.portfolioDialogForm.value.category != undefined ? this.portfolioDialogForm.value.category:'';
    this.portfolio.loginId = Number(sessionStorage.getItem('userId'));
     if(this.data.action == 'add'){
      this.equityService.createPortfolio(this.portfolio).subscribe((res: any) => {
        sessionStorage.setItem('privatePortSessionSel', res.portfolioName);
        this.toast.success('portfolio created successfully!');
            this.closeDialogBox();
        });   
    }else if(this.data.action == 'rename'){
      this.portfolio.id = this.data.portfolio.id;     
      this.equityService.renamePortfolio(this.portfolio).subscribe((res: any) => {
        sessionStorage.setItem('privatePortSessionSel', res.portfolioName);
        this.toast.success('portfolio renamed successfully with '+res.portfolioName);
            this.closeDialogBox();
        });   
      }
    }
      closeDialogBox(){
        this.dialog.closeAll();     
      }
}
