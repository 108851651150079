<div mat-dialog-title>
    {{ data.title }}
    <span class="close">
        <mat-icon mat-dialog-close>close-icon</mat-icon>
    </span>
    <mat-divider></mat-divider>
</div>
<mat-dialog-content>
    <form [formGroup]="portfolioDialogForm">
        <p>&nbsp;</p>
        <div>
            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Watchlist Name</mat-label>
                <input matInput formControlName="watchListName">
            </mat-form-field>   
        </div>    
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions>
    <button mat-button mat-dialog-submit [disabled]="portfolioDialogForm.invalid"
        (click)="createWatchList()">Submit</button>
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>