import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService } from 'src/app/services/user.service';
import { TradeComponent } from '../../trade/trade.component';
import { LoginService } from 'src/app/services/login.service';
import { UserProfile } from 'src/app/model/userprofile';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.css']
})
export class PasswordResetRequestComponent {

  constructor(private router: Router, @Inject(Injector) private readonly injector: Injector, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA)
  public data: { title: string; message: string },
    private loginService: LoginService, private userService: UserService, private tradeComponent: TradeComponent) {

  }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  resetRequestForm!: FormGroup;
  user: UserProfile = new UserProfile();

  ngOnInit() {

    this.resetRequestForm = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email])
      }
    );
  }

  resetPasswordRequest() {
    this.user = this.resetRequestForm.getRawValue();
    // console.log("submitting the resetPasswordRequest form:" + this.user.email);
    if (this.resetRequestForm.valid) {
      this.loginService.resetPasswordRequest(this.user).subscribe((res: any) => {
        this.toast.success(res.message);
        this.closeDialogBox();
      }, (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.toast.error(error.error);
        } else if (error.status === 400) {
          this.toast.error(error.error);
        } else if (error.status === 409) {
          this.toast.error(error.error);
        }
      });
    } else {
      this.toast.error('Please enter valid email id');
    }
  }

  closeDialogBox() {
    this.dialog.closeAll();
  }
}