<div class="container-body">
<mat-tab-group>
  <mat-tab label="Subscribers">
    <div class="mat-elevation-z8">
      <div>
        <mat-form-field appearance="outline" class="sr">
          <mat-label>
            <mat-icon>search</mat-icon>
            search
          </mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
        <button class="right" mat-flat-button color="primary" (click)="openAddSubsriberDialog()">Add Subscriber</button>
      </div>

       <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="tradeTenant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Tenant"> Tenant </th>
          <td mat-cell *matCellDef="let element"> {{element.tradeTenant}} </td>
        </ng-container>

        <ng-container matColumnDef="apisecret">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ApiSecret"> ApiSecret
          </th>
          <td mat-cell *matCellDef="let element"> {{element.apisecret}} </td>
        </ng-container>

        <ng-container matColumnDef="apikey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ApiKey"> ApiKey </th>
          <td mat-cell *matCellDef="let element"><a href="{{element.tenantLoginUrl}}" target="_blank">
              {{element.apikey}}</a> </td>
        </ng-container>

        <ng-container matColumnDef="requestToken">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by requestToken">
            RequestToken </th>
          <td mat-cell *matCellDef="let element let i=index">

            <div *ngIf="!element.editable; else editingTemplate">
              {{element.requestToken}}
              <button mat-button (click)="edit(element)">
                <mat-icon>create</mat-icon>
              </button>
            </div>

            <ng-template #editingTemplate>
              <input matInput [(ngModel)]="element.requestToken" (keyup.enter)="element.editable = false">
              <button mat-button (click)="setRefreshToken(element)" (click)="edit(element)">
                <mat-icon>done</mat-icon>
              </button>
            </ng-template>
          </td>

        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by active"> Active </th>
          <td mat-cell *matCellDef="let element"> {{element.active}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">

            <button mat-button (click)="deleteSubscriber(element,element.userName,'Delete')">
              <mat-icon color="warn">delete</mat-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
       </table>

      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </mat-tab>
  <mat-tab label="Products & Services"> Billing Order History Content


  </mat-tab>
  <mat-tab label="Billing"> Billing content
    <button class="right" mat-flat-button color="primary" (click)="openAddSubsriberDialog()">Add Funds</button>
    <button class="right" mat-flat-button color="warn" (click)="openAddSubsriberDialog()">Withdraw</button>
  </mat-tab>
</mat-tab-group>
</div>