import { Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { UserProfile } from 'src/app/model/userprofile';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/model/Product';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {

  constructor(public userService:UserService,private router:Router,private loginService:LoginService){
  }

  profileForm = new FormGroup(
    {
      id:new FormControl(''),
      name:new FormControl('',[Validators.required]),
      agentId:new FormControl(),
      inputAddress:new FormControl(),
      inputAddress2:new FormControl(),
      inputCity:new FormControl(),
      inputState:new FormControl(),
      inputPincode:new FormControl(),
      toggleControl:new FormControl()
    }
  );

  liveKite!:boolean;
  userProfileData:UserProfile = new UserProfile();
  products!:Product[]; 

  ngOnInit(){
     this.loadUserProfile();
  }
  loadUserProfile(){
    this.loginService.getUserProfileById(sessionStorage.getItem('userId')).subscribe((res:any) => {
      this.userProfileData = res;
      this.profileForm.controls.inputAddress.setValue(this.userProfileData.address);
      this.profileForm.controls.inputAddress2.setValue(this.userProfileData.address2);
      this.profileForm.controls.inputPincode.setValue(this.userProfileData.pincode);
      this.profileForm.controls.inputCity.setValue(this.userProfileData.city);
      this.profileForm.controls.inputState.setValue(this.userProfileData.state);
      this.products = this.userProfileData.products;     
    });
  }
  onSlideClick(value:any){
    console.log("isKiteLive::"+value.checked+" userID::"+this.userService.userId);

    this.userProfileData.id = this.userService.userId;
    this.userProfileData.username = this.userService.userName;
    this.userProfileData.email = this.userService.userEmail;
    this.userProfileData.kiteLive = value.checked;
    this.loginService.updateUserProfile(this.userProfileData).subscribe((res:any) => {
      // this.router.navigateByUrl("trade");
      // this.userProfileData = res;
    });   
  }


  updateProfile(){
    console.log("updating the userProfile:");
    this.userProfileData.kiteLive = this.profileForm.get('toggleControl')?.value;
    this.userProfileData.address = this.profileForm.get('inputAddress')?.value;
    this.userProfileData.address2 = this.profileForm.get('inputAddress2')?.value;
    this.userProfileData.city = this.profileForm.get('inputCity')?.value;
    this.userProfileData.state = this.profileForm.get('inputState')?.value;
    this.userProfileData.pincode = this.profileForm.get('inputPincode')?.value;    

    this.loginService.updateUserProfile(this.userProfileData).subscribe((res:any) => {
      this.loadUserProfile();
    }); 
  } 


}
