export class Portfolio{
    id!:number;
    loginId!:number;
    agentId!:number;
    portfolioName!:string;
    portfolioValue!:string;
    portfolioType!:string;
    portfolioPro!:string;
    description!:string;
    quotedPrice!:number;
    category!:string;
    subscribers!:string[];
    statusUpdate!:string;
}