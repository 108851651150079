<!-- <div *ngIf="userService.isLoggedIn" class="row mr-2 ml-auto"> -->
<div class="page-container">
       <app-header></app-header>

       <!-- Content area -->
       <div class="content">
              <router-outlet></router-outlet>
       </div>
       <div>
              <app-footer></app-footer>
       </div>
</div>