<div mat-dialog-title>
    Add Subscriber
    <span class="close">
        <mat-icon mat-dialog-close>close-icon</mat-icon>
    </span>
</div>
<mat-dialog-content>
    <form [formGroup]="addSubscriberForm">
        <div>

            <mat-form-field appearance="outline" class="form-element">
                <mat-label>User Name</mat-label>
                <input matInput formControlName="userName">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Trade tenant</mat-label>
                <mat-select matNativeControl required formControlName="tradeTenant">
                    <mat-option value="kite">Kite</mat-option>
                    <mat-option value="sharekhan">Sharekhan</mat-option>
                  </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="form-element">
                <mat-label>API Key</mat-label>
                <input matInput formControlName="apikey">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-element">
                <mat-label>API Secret</mat-label>
                <input matInput formControlName="apisecret">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Request Token</mat-label>
                <input matInput formControlName="requestToken">
            </mat-form-field>
           
        </div>
        <div>
            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>  
        </div>
     
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="addSubscriberForm.invalid" (click)="addSubscriber()">Add</button>
    <button mat-raised-button  color="warn" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
