<div mat-dialog-title class="dialog-title">
    {{ data.title }}
    <span class="close">
        <mat-icon mat-dialog-close>close-icon</mat-icon>
    </span>  
</div>
<!-- <mat-divider></mat-divider> -->
<mat-dialog-content>
    <form [formGroup]="portfolioDialogForm" class="portfolio-dialog-form">
        <!-- <mat-divider></mat-divider> -->
    
        <!-- Portfolio Name and Category -->
        <div class="form-row">
            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Portfolio Name</mat-label>
                <input matInput formControlName="portfolioName">
            </mat-form-field>
        </div>
        <div class="form-row">
        <mat-form-field appearance="outline" class="form-element">
            <mat-label>Portfolio Category</mat-label>
            <input matInput formControlName="category" placeholder="Fundamentals, long-term, etc.">
        </mat-form-field>
        </div>
    
        <!-- Portfolio Type Radio Group -->
        <div class="form-row">
            <mat-label>Type:</mat-label>
            <mat-radio-group [(ngModel)]="portfolioTypeSel" [ngModelOptions]="{standalone: true}">
                <mat-radio-button *ngFor="let option of data.portfolioType" [value]="option.value" 
                                  [checked]="option.checked" [disabled]="option.disabled" class="radio-button">
                    {{ option.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    
        <!-- Portfolio Description -->
        <div class="form-row">
            <mat-form-field appearance="outline" class="form-element">
                <mat-label>Portfolio Description</mat-label>
                <textarea matInput #inputDesc [maxLength]="150" formControlName="description"></textarea>
                <mat-hint class="info-hint">{{inputDesc.value.length || 0}}/150</mat-hint>
            </mat-form-field>
        </div>
    
    </form>
    
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="portfolioDialogForm.invalid" (click)="onPortfolioSubmit()">
      Submit
    </button>
    <button mat-stroked-button color="warn" mat-dialog-close>Close</button>
</mat-dialog-actions>


