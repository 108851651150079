import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class KiteConnectService {

  constructor(private http:HttpClient){}

  getQuoteByInstrument(instrumentId:any):Observable<Object>{
    return this.http.get<any>(environment.kiteConnectUrl+'/getPriceByInstrument'+'/'+instrumentId);
  }

  loadExpiryDates(): Observable<any> {
    return this.http.get<any>(environment.optionServicetUrl + '/loadExpiryDates');
  }
  
  loadWeekDates(expiryDate:any): Observable<any> {
    return this.http.get<any>(environment.optionServicetUrl + '/loadWeekDates/'+expiryDate);
  }

  loadStrikeDataByExpiry(expiryDate: any, strikeDate:any): Observable<any> {
    return this.http.get<any>(environment.optionServicetUrl + '/findExpiryAndCurrentDateStrikeData?expiryDate='+ expiryDate+'&strikeDate='+strikeDate);
  }

  loadChainData(expiryDate: any, strikeDate:any): Observable<any> {
    return this.http.get<any>(environment.optionServicetUrl + '/loadChainData?expiryDate='+ expiryDate+'&strikeDate='+strikeDate);
  }
}
