import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SubscriberComponent } from './components/subscriber/subscriber.component';
import { ReportsComponent } from './components/reports/reports.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { EquityComponent } from './components/equity/equity.component';
import { TradeComponent } from './components/trade/trade.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProductsComponent } from './components/products/products.component';
import { PortfolioViewComponent } from './components/equity/portfolio-view/portfolio-view.component';
import { CanactivateGuardComponent } from './guards/canactivate-guard/canactivate-guard.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PasswordResetComponent } from './components/login/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './components/login/password-reset-request/password-reset-request.component';
import { VerifyUserComponent } from './components/login/verify-user/verify-user.component';
import { DataDisplayComponent } from './components/options/data-display/data-display.component';
import { ChainDataComponent } from './components/options/chain-data/chain-data.component';

const routes: Routes = [
  {path:'login',component:LoginComponent},
  {path:'verifyuser',component:VerifyUserComponent},
  {path:'resetpassword',component:PasswordResetComponent},
  {path:'resetpasswordrequest',component:PasswordResetRequestComponent},
  {path:'dashboard',component:DashboardComponent},
  // {path:'',redirectTo:'/login',pathMatch:'full'},
  {path:'',redirectTo:'/dashboard',pathMatch:'full'},
  {path:'home',component:HomeComponent},
  {path:'subscribers',component:SubscriberComponent, canActivate:[CanactivateGuardComponent]},
  {path:'trade',component:TradeComponent, canActivate:[CanactivateGuardComponent]},
  {path:'equity',component:EquityComponent, canActivate:[CanactivateGuardComponent]},
  {path:'products',component:ProductsComponent},
  {path:'reports',component:ReportsComponent, canActivate:[CanactivateGuardComponent]},
  {path:'aboutus',component:AboutusComponent},
  {path:'contactus',component:ContactusComponent, canActivate:[CanactivateGuardComponent]},
  {path:'profile',component:ProfileComponent, canActivate:[CanactivateGuardComponent]},
  {path:'portfolioview',component:PortfolioViewComponent, canActivate:[CanactivateGuardComponent]},
  {path:'optiondisplay',component:DataDisplayComponent, canActivate:[CanactivateGuardComponent]},
  {path:'chaindatadisplay',component:ChainDataComponent, canActivate:[CanactivateGuardComponent]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
