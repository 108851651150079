import { Component, Inject, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Portfolio } from 'src/app/model/portfolio';
import { EquityService } from 'src/app/services/equity.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-watchlist-dialog',
  templateUrl: './add-watchlist-dialog.component.html',
  styleUrls: ['./add-watchlist-dialog.component.css']
})
export class AddWatchlistDialogComponent {
  portfolioTypeSel!:any;
  portfolioProSel!:any;
  triggerPrice!:any;
  trigerPriceDisable!:boolean;


  constructor(private router:Router, private dialog:MatDialog,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(MAT_DIALOG_DATA)  public data: { 
    title: string, message: string,
    portfolio:Portfolio,
    action:string  
  }, 
  private equityService:EquityService,private userService:UserService
  ){    
    if(data.action == 'rename'){
      this.portfolioDialogForm.get('watchListName')?.setValue(sessionStorage.getItem('watchListItemSession'));      
    }
   }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  portfolio:Portfolio = new Portfolio();
  

  portfolioDialogForm = new FormGroup(
    {
      watchListName :new FormControl('',[Validators.required])
    }
  );

 
  createWatchList(){

    this.portfolio.agentId = Number(sessionStorage.getItem('userId'));
    this.portfolio.portfolioName = this.portfolioDialogForm.value.watchListName != undefined ? this.portfolioDialogForm.value.watchListName:'';
    this.portfolio.portfolioValue = this.portfolioDialogForm.value.watchListName != undefined ? this.portfolioDialogForm.value.watchListName:'';
    this.portfolio.portfolioType = 'watchlist';
    this.portfolio.portfolioPro = 'no';
    this.portfolio.quotedPrice = 0;
    this.portfolio.description = '';
    this.portfolio.category = '';
    
     if(this.data.action == 'add'){
      this.equityService.createPortfolio(this.portfolio).subscribe((res: any) => {
        sessionStorage.setItem('watchListItemSession', res.portfolioName);
        this.toast.success('watchlist created successfully!');
            this.closeDialogBox();
        });   
    }else if(this.data.action == 'rename'){
      this.portfolio.id = this.data.portfolio.id;     
      this.equityService.renamePortfolio(this.portfolio).subscribe((res: any) => {
        sessionStorage.setItem('watchListItemSession', res.portfolioName);
        this.toast.success('portfolio renamed successfully with '+res.portfolioName);
            this.closeDialogBox();
        });   
      }
    }
      closeDialogBox(){
        this.dialog.closeAll();     
      }

}
