<div class="center">
    <form [formGroup]="resetForm" (submit)="resetPassword()">
        <div>
            <mat-form-field>
                <input matInput placeholder="Enter New Password" [type]="hideOne ? 'password' : 'text'"
                    formControlName="password" required>
                <mat-icon matSuffix (click)="hideOne = !hideOne">{{hideOne ? 'visibility_off' :
                    'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <input matInput placeholder="Enter Confirm Password" [type]="hideTwo ? 'password' : 'text'"
                    formControlName="confirmPassword" required>
                <mat-icon matSuffix (click)="hideTwo = !hideTwo">{{hideTwo ? 'visibility_off' :
                    'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" type="submit">Reset Password</button>
    </form>
</div>