import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquityService {
  getProfiltBookingCount():Observable<Object>{
    return this.http.get(environment.dashboardServiceUrl+'/getProfitBookings');
  }
  
  
  getPortfolioCount():Observable<Object>{
    return this.http.get(environment.dashboardServiceUrl+'/getPortfolioCount');
  }

  constructor(private http:HttpClient){
  }

  processEntryOrder(orderDetails:any):Observable<Object>{
    // console.log("http POST:processEntryOrder::"+orderDetails);
    return this.http.post(environment.equityServiceUrl+'/processOrderEntry',orderDetails);
  }

  processModifyOrder(orderDetails:any):Observable<Object>{
    // console.log("http POST:processModifyOrder::"+orderDetails.tradingsymbol);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post(environment.equityServiceUrl+'/processModifyOrder',orderDetails, {responseType: 'text'});
  }

  getEquityOrderDetails(agentId:number, portId:number):Observable<Object>{
    // console.log("http POST:getEquityOrderDetails::portId"+portId+",agentID:"+agentId);
    return this.http.get(environment.equityServiceUrl+'/getEquityOrderDetails/'+agentId+'/'+portId);
  }

  getPublicPortfolioItems(portId:number, loggedUserId:any):Observable<Object>{
 //   console.log("http POST:getPublicPortfolioItems::"+portId+",loggedUserId:"+loggedUserId);
    return this.http.get(environment.equityServiceUrl+'/getPublicPortfolioItems/'+portId+'/'+loggedUserId);
  }

  getPortSubscriberDetails(portId:number, loggedUserId:any):Observable<Object>{
    //   console.log("http POST:getPublicPortfolioItems::"+portId+",loggedUserId:"+loggedUserId);
       return this.http.get(environment.equityServiceUrl+'/getPortSubscriberDetails/'+portId+'/'+loggedUserId);
     }

  removeEquityOrder(agentId:any, orderId:any):Observable<Object>{
    // console.log("http POST:removeEquityOrder::"+agentId);
    return this.http.get(environment.equityServiceUrl+'/removeEquityOrder/'+agentId+'/'+orderId);
  }

  getPrivatePortfolioNames(agentId:any):Observable<Object>{
    // console.log("http GET:getPrivatePortfolioNames::"+agentId);
    return this.http.get(environment.equityServiceUrl+'/getPrivatePortfolioNames/'+agentId);
  }

  getWatchListNames(agentId:any):Observable<Object>{
    // console.log("http GET:getWatchListNames::"+agentId);
    return this.http.get(environment.equityServiceUrl+'/getWatchListNames/'+agentId);
  }
  
  getPublicPortfolios(agentId:any):Observable<Object>{
    // console.log("http get:getPublicPortfolios::"+agentId);
    return this.http.get(environment.equityServiceUrl+'/getPublicPortfolios/'+agentId);
  }  

  getAllPorfolios(agentId:any):Observable<Object>{
    // console.log("http get:getAllPorfolios::"+agentId);
    return this.http.get(environment.equityServiceUrl+'/getAllPorfolios/'+agentId);
  }  

  createPortfolio(portfolio:any):Observable<Object>{
    // console.log("http POST:createPortfolio::"+portfolio.portfolioName);
    return this.http.post(environment.equityServiceUrl+'/createPortfolio',portfolio);
  }

  renamePortfolio(portfolio:any):Observable<Object>{
    // console.log("http POST:renamePortfolio::"+portfolio.portfolioName);
    return this.http.post(environment.equityServiceUrl+'/renamePortfolio',portfolio);
  }

  deletePortfolio(portfolio:any):Observable<Object>{
    // console.log("http POST:deletePortfolio::"+portfolio.portfolioName);
    return this.http.post(environment.equityServiceUrl+'/deletePortfolio',portfolio);
  }

  deleteWatchList(portfolio:any):Observable<Object>{
    // console.log("http POST:deleteWatchList::"+portfolio.portfolioName);
    return this.http.post(environment.equityServiceUrl+'/deleteWatchList',portfolio);
  }

  raisePortfolioRequest(portfolio:any):Observable<Object>{
    return this.http.post(environment.equityServiceUrl+'/raisePortfolioRequest',portfolio);
  }

  subscribePortfolio(portfolio:any):Observable<Object>{
    // console.log("http POST:subscribePortfolio::"+portfolio.portfolioName);
    return this.http.post(environment.equityServiceUrl+'/subscribePortfolio',portfolio);
  }

  updatePortSubscriptionStatus
  (portfolio:any):Observable<Object>{
    return this.http.post(environment.equityServiceUrl+'/updatePortSubscriptionStatus',portfolio);
  }
  updateProPortfolioSubscriptionStatus
  (portfolioSubscription:any):Observable<Object>{
    return this.http.post(environment.equityServiceUrl+'/updateProPortfolioSubscriptionStatus',portfolioSubscription);
  }

  getPortfolioMonthlyData(portfolioId:any,periodSelItem:any):Observable<Object>{
    // console.log("http GET:getPortfolioMonthlyData::"+portfolioId);
    return this.http.get(environment.equityServiceUrl+'/getPortfolioMonthlyData/'+portfolioId+'/'+periodSelItem);
  }

  sendMail(emailDetails:any):Observable<Object>{    
    return this.http.post(environment.emailServiceUrl+'/sendMail',emailDetails,{responseType: 'text'});
  }

}
