import { Component, Inject, Injector  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OrderDetails } from 'src/app/model/orderdetails';
import { StrikeData } from 'src/app/model/strikedata';
import { TradeService } from 'src/app/services/trade.service';
import { UserService } from 'src/app/services/user.service';
import { TradeComponent } from '../trade.component';
import { Option } from 'src/app/model/Option';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tradedialog',
  templateUrl: './tradedialog.component.html',
  styleUrls: ['./tradedialog.component.css']
})
export class TradedialogComponent {

  constructor(private router: Router, private dialog: MatDialog, 
    @Inject(Injector) private readonly injector: Injector,
    @Inject(MAT_DIALOG_DATA)
  public data: {
    title: string; message: string;
    orderId: number,
    productType: Option[],
    orderVariety: Option[],
    defOrderTypeDisable: boolean,
    defaultOrderType: string,
    defProductTypeDisable: boolean,
    defProductType: string,
    defTriggerPrice: number,
    defTriggerQnty: number,
    operationType: string,
    exitType: boolean,
    strikeData: StrikeData,
    transactionType: string,
    defaultProductType: string,
    orderStatus: string,
    kiteLive: boolean,
    offlineTrade:boolean
  },
    private tradeService: TradeService, private userService: UserService, private tradeComponent: TradeComponent) {
    this.orderVarietyRadioSel = this.data.defaultOrderType;
    this.productTypeRadioSel = this.data.defProductType;
    this.triggerPrice = this.data.defTriggerPrice;
    this.isOrderVarietyDisabled = this.data.defOrderTypeDisable;
    this.isProductTypeDisabled = this.data.defProductTypeDisable;
    this.triggerQnty = Math.abs(this.data.defTriggerQnty);
    // this.triggerQnty = this.data.defTriggerQnty;
    this.titleColor = this.data.transactionType == 'BUY' ? "Green" : "Red";
  }

  tradeOpenTime: string = '09:15:00';
  tradeCloseTime: string = '15:30:00';
  tradeHoursOut!: boolean;
  orderDetails: OrderDetails = new OrderDetails();

  orderVarietyRadioSel: any;
  productTypeRadioSel: any;
  triggerPrice: any;
  isOrderVarietyDisabled: boolean;
  isProductTypeDisabled: boolean;
  triggerQnty: number;
  titleColor: String;

  tradeDialogForm = new FormGroup(
    {
      triggerPriceForm: new FormControl('')
    }
  );

  private get toast() {
    return this.injector.get(ToastrService);
  }


  onTradeSubmit(operationType: string) {

    console.log("submitting the form with transactionType: " + this.data.transactionType
      + ",OrderVariety:" + this.orderVarietyRadioSel
      + ",productTypeRadioSel:" + this.productTypeRadioSel
      + ",lot_size:" + this.data.strikeData.lot_size
      + ",operationType::" + operationType
      + ",this.triggerQnty::"+this.triggerQnty
    );

    this.orderDetails._id = this.data.orderId;
    this.orderDetails.transactionType = this.data.transactionType;
    this.orderDetails.orderVariety = this.orderVarietyRadioSel;
    this.orderDetails.productType = this.productTypeRadioSel;
    this.orderDetails.strikeData = this.data.strikeData;
    this.orderDetails.triggerPrice = this.triggerPrice;
    this.orderDetails.triggerTimeLtp = this.data.strikeData.last_price;
    this.orderDetails.triggerQnty = this.triggerQnty;
    this.orderDetails.agentId = Number(sessionStorage.getItem('userId'));
    this.orderDetails.kiteLive = this.data.kiteLive;
    this.orderDetails.offlineTrade = this.data.offlineTrade;
    

    if (!this.orderDetails.kiteLive && !this.orderDetails.offlineTrade) {
      this.toast.info('Required to have either "kiteLive" or "offline trade" subscription in order to process the trade.');
    } else if (this.orderDetails.kiteLive && this.verifyTradeHours()) {
      this.toast.info('Current time is out of the market hours!');
    } else {
      this.orderDetails.orderStatus = this.data.orderStatus;
      if (operationType == "ADD" || operationType == "EXIT" || operationType == "BUY" || operationType == "SELL") {
        this.tradeService.processEntryOrder(this.orderDetails).subscribe((res: any) => {
          this.closeDialogBox();
        });
      } else if (operationType == "CANCELLIMIT") {
        this.tradeService.cancelLimitOrder(this.orderDetails).subscribe((res: any) => {
          this.closeDialogBox();
        });
      } else if (operationType == "MODIFYLIMIT") {
        if(this.productTypeRadioSel == "MARKET"){
          this.orderDetails.triggerPrice = this.data.strikeData.last_price;
        }
        this.tradeService.modifyLimitOrder(this.orderDetails).subscribe((res: any) => {
          this.closeDialogBox();
        });
      }
    }
  }

  verifyTradeHours() {
    const currentTime = new Date();
    const fixedTimeParts = this.tradeCloseTime.split(':');
    const tradeCloseDate = new Date();
    tradeCloseDate.setHours(parseInt(fixedTimeParts[0], 10));
    tradeCloseDate.setMinutes(parseInt(fixedTimeParts[1], 10));
    tradeCloseDate.setSeconds(parseInt(fixedTimeParts[2], 10));

    const tradeOpenTime = new Date();
    const fixedOpenTimeParts = this.tradeOpenTime.split(':');
    tradeOpenTime.setHours(parseInt(fixedOpenTimeParts[0], 10));
    tradeOpenTime.setMinutes(parseInt(fixedOpenTimeParts[1], 10));
    tradeOpenTime.setSeconds(parseInt(fixedOpenTimeParts[2], 10));
    if (currentTime < tradeOpenTime || currentTime > tradeCloseDate) {
      return true;
    } else {
      return false;
    }
  }

  closeDialogBox() {
    this.dialog.closeAll();
  }
}
