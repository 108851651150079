<div class="center-form">
<form class="row g-3" [formGroup]="profileForm" (ngSubmit) = "updateProfile()" >
    <div class="col-md-3">
        <label for="inputName" class="form-label">Name : </label>
        <span>{{userProfileData.loggedInUser}}</span>
      </div>
    <div class="col-md-3">
      <label for="inputEmail4" class="form-label">Email : </label>
      <span>{{userProfileData.email}}</span>
    </div>
    <div class="col-md-4">
        <label for="kiteLive" class="form-label">KiteLive : </label>
        <span>{{userProfileData.kiteLive}}</span>
      </div>
    <div class="col-md-3">
      <label for="inputPassword4" class="form-label">KiteConnect : </label>
      <mat-slide-toggle (change)="onSlideClick($event)" [checked]='userProfileData.kiteLive' 
      formControlName="toggleControl" id="toggleControl"  value="message_comment">LiveTrade</mat-slide-toggle>
    </div>
    <div class="col-md-3">
        <label for="inputPhone" class="form-label">Phone : </label>
        <span>{{userProfileData.phone}}</span>
      </div>
    <div class="col-10">
      <label for="inputAddress" class="form-label">Address : </label>
      <input type="text" class="form-control" id="inputAddress" formControlName="inputAddress" placeholder="1234 Main St">
    </div>
    <div class="col-10">
      <label for="inputAddress2" class="form-label">Address 2 : </label>
      <input type="text" class="form-control" id="inputAddress2"  formControlName="inputAddress2" placeholder="Apartment, studio, or floor">
    </div>
    <div class="col-md-6">
      <label for="inputCity" class="form-label">City : </label>
      <input type="text" class="form-control" id="inputCity" formControlName="inputCity">
    </div>
    <div class="col-md-4">
      <label for="inputState" class="form-label">State : </label>
      <input type="text" class="form-control" id="inputState" formControlName="inputState">
      <!-- <select id="inputState" class="form-select">
        <option selected>Choose...</option>
        <option>...</option>
      </select> -->
    </div>
    <div class="col-md-6">
        <label for="inputCity" class="form-label">PinCode : </label>
        <input type="text" class="form-control" id="inputPincode" formControlName="inputPincode">
      </div>
    <div class="col-10">
      <button type="submit" class="btn btn-primary">Update</button>
    </div>
  </form>

  
</div>
<div class="center-form">
<table class="table align-middle mb-0 bg-white">
  <thead class="bg-light">
    <tr>
      <th>ProductName</th>
      <th>ProductType</th>
      <th>CreatedDate</th>
      <th>ExpiryDate</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let x of products">
    <td>{{ x.productName }}</td> 
    <td>{{ x.productType }}</td>   
    <td>{{ x.createdDate }}</td> 
    <td>{{ x.expiryDate }}</td>  
    <td>{{ x.status }}</td>      
  </tr></tbody>
</table>  
</div>
