<div class="container-body">

  <div>&nbsp;</div>
  <div class="portfolio-title-bar">
    <button class="custom-button" (click)="addPortfolioNameDialogOpen('add','Create Portfolio')">
      <mat-icon>library_add</mat-icon>CREATE NEW PORTFOLIO
    </button>
  </div>
  <div>&nbsp;</div>
  <div class="portfolio-container">
    <mat-label class="portfolio-label">Your Portfolios</mat-label>

    <div class="custom-search-container">
      <input type="text" class="custom-search-input" placeholder="Search category, portfolio name/type"
        (keyup)="applySubscrebedPortfolioFilter($event)" #inputSubscribedPort>
      <button class="search-button">
        <mat-icon>search</mat-icon>
      </button>
    </div>

    <div class="table-wrapper">
      <mat-table [dataSource]="subscribedPortfoliosSource" matSort #subscribedPortfoliossort="matSort">
        <ng-container matColumnDef="portfolioName">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by portfolioName"
            class="sticky-column">
            Portfolio Name
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="sticky-column">
            <a href="#" routerLinkActive="active" routerLink="/portfolioview"
              [queryParams]="{portfolioId:element.id,portfolioName:element.portfolioName}">
              {{ element.portfolioName }}
            </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">
            Description </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by category"> Category
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.category}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="portfolioType">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by portfolioType">
            PortfolioType
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.portfolioType}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <!-- <button mat-button color="primary" *ngIf="!checkIfSubscribeEnabled(element)"
                (click)="subscriberPortfolio(element,'Subscribe')">Subscribe for free
              </button> -->
            <!-- <button mat-button color="primary" *ngIf="checkIfRenewalEnabled(element)"
                (click)="renewSubscribePortfolio(element,'Renewal')">Renew</button> -->
            <button mat-button color="primary" (click)="unSubscribePortfolio(element,'unsubscribe')"
              matTooltip="unSubscribe Portfolio">
              <mat-icon>unsubscribe</mat-icon></button>
            <button mat-button color="primary" [cdkCopyToClipboard]="buildShareLink(element.id,element.portfolioName)"
              matTooltip="Copy sharable link to clipboard"><mat-icon>link</mat-icon></button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="getFreeSubPortDisplayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: getFreeSubPortDisplayedColumns()"></mat-row>
      </mat-table>
    </div> <!-- closing table-wrapper -->
    <div class="paginator-container">
    <mat-paginator [pageSizeOptions]="[10, 15, 20]" #subscribedPortfoliosPaginator showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator></div>
  </div> <!-- closing portfolio-container -->
  <div> &nbsp;</div>

  <div class="portfolio-container">
    <mat-label class="portfolio-label">Public Portfolios</mat-label>
    <div class="custom-search-container">
      <input type="text" class="custom-search-input" placeholder="Search category, portfolio name/type"
        (keyup)="applyFreeSubscriptionPortfolioFilter($event)" #inputSubscribedPort>
      <button class="search-button">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <mat-table [dataSource]="freeSubscriptionPortifoliosSource" matSort #freeSubPortfoliosort="matSort">
        <ng-container matColumnDef="portfolioName">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by portfolioName"
            class="sticky-column">
            Portfolio Name
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="sticky-column">
            <a href="#" routerLinkActive="active" routerLink="/portfolioview"
              [queryParams]="{portfolioId:element.id,portfolioName:element.portfolioName}">
              {{ element.portfolioName }}
            </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">
            Description </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by category"> Category
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.category}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="portfolioType">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by portfolioType">
            PortfolioType
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.portfolioType}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="getFreeSubPortDisplayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: getFreeSubPortDisplayedColumns()"></mat-row>
      </mat-table>
    </div> <!-- closing table-wrapper -->
    <div class="paginator-container">
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" #freeSubPortfolioPaginator showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator></div>
  </div> <!-- closing portfolio-container -->
  <div> &nbsp;</div>
</div>