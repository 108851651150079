<!-- <nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>                        
      </button>
      <a class="navbar-brand" href="#">Logo</a>
    </div>
<div class="collapse navbar-collapse" id="myNavbar">
  <ul class="nav navbar-nav">
    <li class="active"><a class="nav-link"  href="#" routerLinkActive="active" 
      [routerLinkActiveOptions]={exact:true} routerLink="/dashboard">Dashboard</a></li>
    <li><a class="nav-link"  href="#" routerLinkActive="active" routerLink="/trade">Trade</a></li>
    <li><a class="nav-link"  href="#" routerLinkActive="active" routerLink="/equity">Portfolios</a></li>
    <li><a class="nav-link" href="#" routerLinkActive="active" routerLink="/products">Products</a></li>
    <li><a class="nav-link" href="#" routerLinkActive="active" routerLink="/aboutus">AboutUs</a></li>
  </ul>
  <ul class="nav navbar-nav navbar-right">
    <li *ngIf="!userService.isLoggedIn"><a href="#"><span class="glyphicon glyphicon-log-in"></span>Login</a></li>
  </ul>
</div>
</div>
</nav> -->


<mat-toolbar class="large-toolbar">
  <span class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" 
    [routerLinkActiveOptions]={exact:true} routerLink="/dashboard">Dashboard</a>  </span>
  <span *ngIf="sessionUserId != null" class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" routerLink="/trade">Trade</a>  </span>
  <span *ngIf="sessionUserId != null" class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" routerLink="/equity">Portfolios</a> </span>  
  <span class="menu-title"> <a class="nav-link" href="#" routerLinkActive="active" routerLink="/products">Products</a> </span>
  <span class="menu-title"> <a class="nav-link" href="#" routerLinkActive="active" routerLink="/aboutus">AboutUs</a></span>
  <span *ngIf="sessionUserId == null" class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" routerLink="/login">SignIn</a>  </span>            
    
    <div *ngIf="sessionUserId != null">    
              <button mat-button [matMenuTriggerFor]="menu">                  
                  <!-- <span>{{userService.userName}}</span> -->
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <img src="../../assets/user-icon.jpg" alt="" class="user-image mr-1 p-2">
              </button>
              <mat-menu #menu="matMenu">
                   <a mat-menu-item href="#" routerLink="/profile">
                    <mat-icon>account_circle</mat-icon>Profile</a>
                    <a mat-menu-item href="#" routerLink="/subscribers">
                        <mat-icon>payment</mat-icon>Renewals & Billing</a>
                        <a mat-menu-item href="#" routerLink="/reports">
                            <mat-icon>payment</mat-icon>Reports</a>
                            <a mat-menu-item href="#" routerLink="/contactus">
                              <mat-icon>contact_mail</mat-icon>ContactUs</a>
                            <a *ngIf="hasRoleOf('Admin')" mat-menu-item href="#" routerLink="/optiondisplay">
                                <mat-icon>contact_mail</mat-icon>OptionDisplay</a>
                            <a *ngIf="hasRoleOf('Admin')" mat-menu-item href="#" routerLink="/chaindatadisplay">
                                  <mat-icon>contact_mail</mat-icon>ChainDisplay</a>
                    <a mat-menu-item (click)="logoutUser()" >
                      <mat-icon>exit_to_app</mat-icon>
                      Logout
                 </a>
              </mat-menu>
  </div>
</mat-toolbar> 

<mat-toolbar class="small-toolbar">
  <span class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" 
    [routerLinkActiveOptions]={exact:true} routerLink="/dashboard">
    <div class="icon-text-container">
      <mat-icon class="portfolio-icon">dashboard</mat-icon>
      <span class="portfolio-text">Dashboard</span>
    </div> 
  </a>  </span>
  <span *ngIf="sessionUserId != null" class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" routerLink="/trade">
    <div class="icon-text-container">
      <mat-icon class="portfolio-icon">timeline</mat-icon>
      <span class="portfolio-text">Trade</span>
    </div> 
  </a>  </span>
  <span *ngIf="sessionUserId != null" class="menu-title"> <a class="nav-link"  href="#" routerLinkActive="active" routerLink="/equity">
    <div class="icon-text-container">
      <mat-icon class="portfolio-icon">category</mat-icon>
      <span class="portfolio-text">Portfolios</span>
    </div> 
  </a>
  </span>  
  <span class="menu-title"> <a class="nav-link" href="#" routerLinkActive="active" routerLink="/products">
    <div class="icon-text-container">
      <mat-icon class="portfolio-icon">credit_card</mat-icon>
      <span class="portfolio-text">Products</span>
    </div> 
  </a> 
</span>  
  <span *ngIf="sessionUserId == null" class="menu-title"> 
    <a class="nav-link"  href="#" routerLinkActive="active" 
    routerLink="/login">
    <div class="icon-text-container">
      <mat-icon class="portfolio-icon">login</mat-icon>
      <span class="portfolio-text">SignIn</span>
    </div> 
  </a>  
  </span>   
  <span class="menu-title" *ngIf="sessionUserId != null" [matMenuTriggerFor]="menu" routerLinkActive="active">   
    <div class="icon-text-container">
      <mat-icon class="portfolio-icon">account_circle</mat-icon>
      <span class="portfolio-text">ACCOUNT</span>
    </div>  
  </span>       
  <mat-menu #menu="matMenu">
    <a mat-menu-item href="#" routerLink="/profile">
     <mat-icon>account_circle</mat-icon>Profile</a>
     <a mat-menu-item href="#" routerLink="/subscribers">
         <mat-icon>payment</mat-icon>Renewals & Billing</a>
         <a mat-menu-item href="#" routerLink="/reports">
             <mat-icon>payment</mat-icon>Reports</a>
             <a mat-menu-item href="#" routerLink="/contactus">
               <mat-icon>contact_mail</mat-icon>ContactUs</a>
             <a mat-menu-item href="#" routerLink="/aboutus">
                 <mat-icon>contact_mail</mat-icon>AboutUs</a>
             <a *ngIf="hasRoleOf('Admin')" mat-menu-item href="#" routerLink="/optiondisplay">
                 <mat-icon>contact_mail</mat-icon>OptionDisplay</a>
             <a *ngIf="hasRoleOf('Admin')" mat-menu-item href="#" routerLink="/chaindatadisplay">
                   <mat-icon>contact_mail</mat-icon>ChainDisplay</a>
                   
     <a mat-menu-item (click)="logoutUser()" >
       <mat-icon>exit_to_app</mat-icon>
       Logout
  </a>
</mat-menu>     
 
</mat-toolbar> 
