import { Component, Inject, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SubscriberService } from 'src/app/services/subscriber.service';


@Component({
  selector: 'app-addsubscriber',
  templateUrl: './addsubscriber.component.html',
  styleUrls: ['./addsubscriber.component.css']
})
export class AddsubscriberComponent {

  constructor(private subscriberService:SubscriberService,@Inject(Injector) private readonly injector: Injector,private dialog:MatDialog,private userService:UserService){}

  addSubscriberForm=new FormGroup(
    {
      id:new FormControl(''),
      name:new FormControl('',[Validators.required]),
      agentId:new FormControl(),
      tradeTenant:new FormControl(),
      apikey:new FormControl('',[Validators.required]),
      apisecret:new FormControl(''),
      requestToken:new FormControl('',[Validators.required]),
      accessToken:new FormControl(''),
      publicToken:new FormControl(''),    
      email:new FormControl('',[Validators.required,Validators.email]),
      active:new FormControl(''),
      userName:new FormControl('',[Validators.required])
    }
  );

  private get toast() {
    return this.injector.get(ToastrService);
  }

  addSubscriber()
  {
    console.log("Add subsciber Event::",this.addSubscriberForm.value);
    this.addSubscriberForm.value.agentId = sessionStorage.getItem('userId');
    this.subscriberService.saveSubscriber(this.addSubscriberForm.value).subscribe((res:any)=>{
      this.toast.success(res);
     })
     this.closeDialogBox();
    }
  
    closeDialogBox(){
      this.dialog.closeAll();
    }
    
}
