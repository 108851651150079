<div class="container-body">
<div class="col-md-12">
    <div class="card">                        
          <h2>Help fintip to help you better with premium subscription</h2>
    </div>
  </div>
<div class="row">      
    <div class="col-md-6 mb-6 stretch-card">
        <h3 class="p-3 mb-2 bg-primary text-white">FREE PLAN DETAILS</h3>
        <table>
            <tr><td><mat-icon>arrow_right</mat-icon>Creation of portfolios max of 5 and limit of 20 items each</td></tr>
            <tr><td><mat-icon>arrow_right</mat-icon>No Mail reminders on adding of item in the portfolio</td></tr>
            <tr><td><mat-icon>arrow_right</mat-icon>Subscription of portfolios upto 5</td></tr>
        </table>
    </div>
    <div class="col-md-6 mb-6 stretch-card">
        <h3 class="p-3 mb-2 bg-primary text-white">PREMIUM PLAN DETAILS</h3>
        <table>
            <tr><td><mat-icon>arrow_right</mat-icon>Mail reminders on portfolio subscription</td></tr>
            <tr><td><mat-icon>arrow_right</mat-icon>Offline intraday with zero investment</td></tr>
            <tr><td><mat-icon>arrow_right</mat-icon>Unlimited portfolios creation and access</td></tr>
            <tr><td class="text-secondary"><mat-icon>arrow_right</mat-icon>Download portfolios</td></tr>
            <tr><td class="text-secondary"><mat-icon>arrow_right</mat-icon>Allow subscriber to activate/add item on a future price</td></tr>            
        </table>
        <button mat-button color="primary" [disabled]="true">BUY</button>
    </div>

</div>  
<div>
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>BP - Build Portfolios to track of analysis </span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <ul>
                <li>Build strategical portfolios (intraday, longterm, shortterm, fundamentals) </li>
                <li>Make your portfolio private or public depends on the need. public portfolios are visible to other subscribers on fintip application</li>
                <li>users can track of each portfolio and recommendations from the public portfolio section, can go for subscription</li>
                <li>can share the portfolio with the help of share link</li>
            </ul>
            <p>listed verified portfolios <a href="#" routerLinkActive="active"
                routerLink="/equity" [queryParams]= "{selectedTab:1}">Portfolio Library</a></p>
                
                
           
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div>
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    ETO - Execute Trade Offline
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Improve intraday skills by doing the trade offline. Absolutely no money required to learn Intraday and verify the results with the help of 
                reports.
                Establish the confidence and move on to the real world!!!</p>
            <mat-list>offline trade plans
                <mat-list-item>Execute Trade Offline for 1 month   (ETO1)  – xxx/</mat-list-item>
                <mat-list-item>Execute Trade Offline for 3 months  (ETO3)  – xxx/ </mat-list-item>
                <mat-list-item>Execute Trade Offline for 6 months  (ETO6)  – xxx/</mat-list-item>
                <mat-list-item>Execute Trade Offline for 12 months (ETO12) – xxx/ </mat-list-item>
            </mat-list>
            <button mat-button color="primary" [disabled]="true">BUY</button>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div>
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>ETMA - Execute Trade in Multiple Accounts</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Share the your manual trade with your friends.Execute orders manually in fintip to have same order placed in friends/others
                accounts with the help of ETMA product</p>
            <mat-list>ETMA trade plans
                <mat-list-item>ETMA trade for 1 month (ETMAM1)    – xxx/</mat-list-item>
                <mat-list-item>ETMA trade for 3 months (ETMAM3)   – xxx/ </mat-list-item>
                <mat-list-item>ETMA trade for 6 months (ETMAM6)   – xxx/</mat-list-item>
                <mat-list-item>ETMA trade for 12 months (ETMAM12) – xxx/ </mat-list-item>
            </mat-list>
            <button mat-button color="primary" [disabled]="true">BUY</button>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div>
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>ETCA - Execute Trade with Custom Algos</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Have any specific trade logic on your mind to be executed automatically ?
            <p>Setup the logic, apply the logic on historical data, understand the result, 
                and set algo on to trade automatically on your account or multiple accounts.
            </p>
           <p>enabling this option soon....or reach us on quickest possiblity</p>
            <button mat-button color="primary" [disabled]="true">BUY</button>
        </mat-expansion-panel>
    </mat-accordion>
</div>
</div>