import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserProfile } from 'src/app/model/userprofile';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent {


  resetForm!: FormGroup;
  token!: string;
  user: UserProfile = new UserProfile();
  hideOne = true;
  hideTwo = true;

  constructor(private route: ActivatedRoute, private router: Router, private loginService: LoginService,
    @Inject(Injector) private readonly injector: Injector) { }


  private get toast() {
    return this.injector.get(ToastrService);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.token = params['pswToken'];
      }
    )
    this.initForm();
  }
  initForm() {
    this.resetForm = new FormGroup(
      {
        password: new FormControl('', [Validators.required, Validators.min(3)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.min(3)])
      }
    )
  }

  resetPassword() {
    this.user = this.resetForm.getRawValue();
    var validPsw = (this.user.password == this.user.confirmPassword) ? true : false
    if (this.resetForm.valid && validPsw) {
      this.user.resetToken = this.token;
      this.loginService.resetPassword(this.user).subscribe((res: any) => {
        if (res.success) {
          this.toast.success(res.message);
          this.router.navigateByUrl("login");
        } else if (!res.success) {
          this.toast.error(res.message);
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 400 || error.status === 409) {
          this.toast.error(error.error);
        }
      });
    } else {
      this.toast.error('Enter password details correctly.');
    }
  }
}
