import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, tap} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserProfile} from '../model/userprofile'
import { environment } from './../../environments/environment';

const httpOptions = {
 headers: new HttpHeaders({'content-Type':'application/json'})
};

@Injectable({
    providedIn:'root'
})

export class LoginService{

    private logged = new ReplaySubject<boolean>(1);
    isLogged = this.logged.asObservable();

    constructor(private http:HttpClient){}

    signin(userProfile:UserProfile):Observable<Object>{
        // console.log("logging into the application");
        return this.http.post(environment.userServiceUrl+'/signin',userProfile).pipe(
            tap(res => 
                this.setUserSession(res))
          );
    }

    verifyUser(userProfile:UserProfile):Observable<Object>{
        return this.http.post(environment.userServiceUrl+'/verifyUser',userProfile);
    }

    resetPasswordRequest(userProfile:UserProfile):Observable<Object>{
        // console.log("reset password request the password");
        return this.http.post(environment.userServiceUrl+'/resetPasswordRequest',userProfile);
    }

    resetPassword(userProfile:UserProfile):Observable<Object>{
        // console.log("resetting the password");
        return this.http.post(environment.userServiceUrl+'/processResetPassword',userProfile);
    }

    setUserSession(res:any){
        if (res.id != null) {
            sessionStorage.setItem('userId', res.id);
            sessionStorage.setItem('userRoles', res.roles);
            //notify the subscriber
            this.logged.next(true);
        }
    }

    checkStatus() {
        // console.log('checkStatus in loginservice::',sessionStorage.getItem('userId'));
        if (sessionStorage.getItem('userId') != null) {
          this.logged.next(true);
        } else {
          this.logged.next(false);
        }
      }

    signout(){
        // console.log("logout from the application");
        return this.http.post(environment.userServiceUrl+'/signout',{"refreshToken":sessionStorage.getItem('refreshToken')});
    }

    signup(userProfile:UserProfile):Observable<Object>{
        // console.log("signingup into the application");
        return this.http.post(environment.userServiceUrl+'/signup',userProfile);
    }

    refreshToken(token:string){
        return this.http.post(environment.refreshTokenUrl,{refreshToken:token}, httpOptions);
    }

    getUserProfileById(agentId:any):Observable<any>{
        // console.log("getting getUserProfileById list for agentID"+agentId);
        return this.http.get<any>(environment.userProfileServiceUrl+'/getUserProfileById/'+agentId);
    }

    updateUserProfile(userProfile:UserProfile):Observable<Object>{
        // console.log("logging into the application");
        return this.http.post(environment.userProfileServiceUrl+'/updateUserProfile',userProfile);
    }

}