import { Product } from "./Product";

export class UserProfile{
    id!: number;
    username!:string;
    loggedInUser!:string;
    password!:string;
    confirmPassword!:string;
    resetToken!:string;
    email!:string;
    phone!:number;  
    kiteLive!:boolean;
    offlineTrade!:boolean;
    address!:string;
    address2!:string;
    city!:string;
    state!:string;
    pincode!:number;
    products!:Product[];
}