import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private http: HttpClient) { }

  generateAccessToken(subscriber: any): Observable<Object> {
    return this.http.post(environment.subscriberServiceUrl + '/generateAccessToken', subscriber, { responseType: 'text' });
  }

  deleteSubscriber(subscriber: any) {
    return this.http.post(environment.subscriberServiceUrl + '/deleteSubscriber', subscriber);
  }


  getSubscriberList(agentId: any): Observable<any> {
    return this.http.get<any>(environment.subscriberServiceUrl + '/getSubscriberList/' + agentId);
  }

  saveSubscriber(subscriber: Object): Observable<Object> {
    return this.http.post(environment.subscriberServiceUrl + '/registerSubscriber', subscriber, { responseType: 'text' });
  }

}
