<div mat-dialog-title class="dialog-title">
    Confirm {{data.title}}
    <span class="close">
        <mat-icon [mat-dialog-close]>close-icon</mat-icon>
    </span>
</div>
<mat-dialog-content>
    {{data.message}}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial color="warn">No</button>
    <button mat-button [mat-dialog-close]="true" color="primary">Yes</button>
</mat-dialog-actions>