import { StrikeData } from "./strikedata";

export class OrderDetails{
    _id!:number;
    agentId!:number;
    strikeData!:StrikeData;
    transactionType!:string;
    triggerPrice!:number; 
    triggerTimeLtp!:number; 
    profitLoss!:number;
    totalAmt!:number;
    totalProfitLoss!:number;
    orderVariety!:string; 
    productType!:string;
    triggerQnty!:number;    
    orderStatus!:string;
    createdDate!:string;
    kiteLive!:boolean;
    offlineTrade!:boolean;  
}