import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable() 
export class AuthInterceptor implements HttpInterceptor {
    static accessToken = '';
    static refreshToken = '';
    refresh = false;
    constructor(private http: HttpClient, private loginService: LoginService, private router: Router, private userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("AuthInterceptor Token:::", AuthInterceptor.accessToken)
        request = request.clone({
            setHeaders: {
                'Content-Type': 'application/json;charset=utf-8', 'Accept': 'application/json',
                Authorization: `Bearer ${AuthInterceptor.accessToken}`
            }
        });
      
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            console.log("Status ", err.status, "refresh::: ", this.refresh)
            if (err.status === 401 && !this.refresh) {
                console.log("page is refreshed, resetting the tokens again...");
                this.refresh = true 
                // console.log("RefreshToken:::",sessionStorage.getItem('refreshToken'));
                return this.http.post(environment.refreshTokenUrl,  { "refreshToken": sessionStorage.getItem('refreshToken')})
                .pipe(
                    switchMap((res: any) => {
                    AuthInterceptor.accessToken = res.accessToken; 
                    AuthInterceptor.refreshToken = res.refreshToken; 
                    // console.log("Setting accessToken after :::", AuthInterceptor.accessToken)
                    //  console.log("old refresh token", sessionStorage.getItem('refreshToken')); 
                     sessionStorage.removeItem('refreshToken');
                     sessionStorage.setItem('refreshToken', res.refreshToken)
                    //  console.log("new refresh from session after setting:", sessionStorage.getItem('refreshToken'));
                    return next.handle(request.clone(
                        { setHeaders: 
                            { 
                                Authorization: `Bearer ${AuthInterceptor.accessToken}` 
                            } 
                        })
                    );
                }));
            }else{
                console.error('Internal error from fintip angular. admin to be corrected here!!!', err.error);
                if (err.status === 401) {
                    this.router.navigate(['login']);
                }
            }
            this.refresh = false;
            return throwError(() => err);
        }));
    }
}