import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import {MatCheckboxModule} from '@angular/material/checkbox';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent {
  panelOpenState = false;
  constructor(private router:Router,
    private userService:UserService){

 }

 publicPortifolioNames:any=[];
loggedUserId!:number;
 ngOnInit() {}

}
