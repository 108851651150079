<div mat-dialog-title class="dialog-title">
  <span>Reset Password</span>
  <span class="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </span>
</div>

<mat-divider></mat-divider>

<mat-dialog-content>
  <form [formGroup]="resetRequestForm">
    <mat-form-field appearance="outline" class="form-element">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-submit color="primary" (click)="resetPasswordRequest()">Submit</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
