import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EquityOrderDetails } from 'src/app/model/equityOrderDetails';
import { StrikeData } from 'src/app/model/strikedata';
import { EquityService } from 'src/app/services/equity.service';
import { UserService } from 'src/app/services/user.service';
import { TradeComponent } from '../../trade/trade.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TradeService } from 'src/app/services/trade.service';
import { ToastrService } from 'ngx-toastr';
import { KiteConnectService} from 'src/app/services/kite-connect.service';

@Component({
  selector: 'app-add-stock-dialog',
  templateUrl: './add-stock-dialog.component.html',
  styleUrls: ['./add-stock-dialog.component.css']
})
export class AddStockDialogComponent {

  constructor(private router:Router, private dialog:MatDialog,
    @Inject(Injector) private readonly injector: Injector,
     @Inject(MAT_DIALOG_DATA)  public data: { title: string, message: string,
    orderId:number,
    portfolioId:number,
    portfolioName:string,
    defaultPrice: number,
    transactionType:string,
    orderStatus:string
    operationType:string,
    exitType:boolean,
    strikeData:StrikeData   
  }, 
  private equityService:EquityService, 
  private userService:UserService,private tradeService: TradeService, private kiteConnect:KiteConnectService,
  private tradeComponent:TradeComponent
  ){    
    this.triggerPrice = this.data.defaultPrice;    
    }

    orderDetails: EquityOrderDetails = new EquityOrderDetails();
    triggerPrice!:number;
    triggerQnty!:number;
    strikeData!: StrikeData[];
    selectedStrike!: StrikeData; 

    tradeDialogForm = new FormGroup(
      {
        searchField :new FormControl(''),
        instrument_token:new FormControl(0),
        triggerPrice:new FormControl(0,[Validators.required,Validators.pattern("^[0-9]+(\\.[0-9]+)*$"), Validators.minLength(0),Validators.maxLength(10)]),
        selectedStock:new FormControl(''),
        exchangeType:new FormControl(''),
        selectedStatus:new FormControl('')
      }
    );

    clearSearchField(){
      this.tradeDialogForm.get('searchField') ?.setValue('');
      this.strikeData = [];
    }

    searchStrikes(){
      // console.log("searchVal length::" + this.tradeDialogForm.value.searchField?.length);
      if  (this.tradeDialogForm.value.searchField?.length == 0) {
        this.strikeData = [];
      } else if (this.tradeDialogForm.value.searchField != undefined 
        && this.tradeDialogForm.value.searchField?.length  > 2) {
          this.getEquityStrikeBySearch(this.tradeDialogForm.value.searchField);
      }    
    }

    onStrikeSelection(selectedStrike:StrikeData) {
      // console.log("user selection on mat select option::" + selectedStrike);
      this.kiteConnect.getQuoteByInstrument(selectedStrike.instrument_token).subscribe((res: any) => {
        this.tradeDialogForm.get('triggerPrice') ?.setValue(res);
        selectedStrike.last_price = res;
      });
      if(this.tradeDialogForm != null &&  this.tradeDialogForm.get('searchField') != null){
        this.tradeDialogForm.get('searchField') ?.setValue(selectedStrike.tradingsymbol);
        this.tradeDialogForm.get('instrument_token') ?.setValue(selectedStrike.instrument_token);       
        this.strikeData = [];
        this.selectedStrike = selectedStrike;
       } 
    }
  
    getEquityStrikeBySearch(searchkeyword: string) {
      // console.log("getStrikeBySearch::" + searchkeyword);
      this.tradeService.searchStrikeDataByExchange(searchkeyword,sessionStorage.getItem('userId'),this.data.portfolioId).subscribe((res: any) => {
        this.strikeData = res;
        // console.log("loaded strike search data from service size:" + this.strikeData.length);
      });
    }

    private get toast() {
      return this.injector.get(ToastrService);
    }
  

    onTradeSubmit(portfolio:any){
    console.log("tradeDialogForm value::"+this.tradeDialogForm.value)
      console.log("submitting the form with transactionType: "+this.data.transactionType  
      +",this.tradeDialogForm.value.selectedStock:::"+this.tradeDialogForm.value.searchField 
      +",this.data.portfolioName::"+this.data.portfolioName
      +",this.data.portfolioId::"+this.data.portfolioId
      );   
      this.orderDetails.transactionType = "BUY";      
      this.orderDetails.portfolioName = this.data.portfolioName;
      this.orderDetails.portfolioId = this.data.portfolioId;
      this.orderDetails.tradingsymbol = this.tradeDialogForm.value.searchField != undefined ? this.tradeDialogForm.value.searchField:'';
      this.orderDetails.triggerPrice = this.tradeDialogForm.value.triggerPrice != null ? this.tradeDialogForm.value.triggerPrice:0;
      this.orderDetails.agentId = Number(sessionStorage.getItem('userId'));
      this.orderDetails.orderStatus = this.tradeDialogForm.value.selectedStatus != null ? this.tradeDialogForm.value.selectedStatus:'';
      this.orderDetails.strikeData = this.selectedStrike;

      this.equityService.processEntryOrder(this.orderDetails).subscribe((res: any) => {
        this.toast.success('Stock Added Successfully!');
            this.closeDialogBox();
        });   
      }
  
    closeDialogBox(){
      this.dialog.closeAll();    
    }

}
