<div mat-dialog-title class="dialog-title">
    {{ data.title }}
    <span class="close">
        <mat-icon mat-dialog-close>close-icon</mat-icon>
    </span>    
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <form [formGroup]="tradeDialogForm" class="trade-dialog-form">
    
      <!-- Search Symbol Field -->
      <div class="form-row">
        <mat-form-field appearance="outline" class="form-element">
          <mat-label>Search Symbol</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search symbol" autocomplete="off" formControlName="searchField" (keyup)="searchStrikes()">
          <button mat-icon-button matSuffix (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
         <!-- Selection List for Strikes -->
        <mat-selection-list #strike formControlName="selectedStock" class="form-element">
          <mat-option *ngFor="let strike of strikeData" [value]="strike" (click)="onStrikeSelection(strike)">
            <div class="strike-item">
              {{strike.tradingsymbol}} | {{strike.exchange}}
            </div>
          </mat-option>
        </mat-selection-list>
      </div>
    
      <!-- Price Input Field -->
      <div class="form-row">
        <mat-form-field appearance="outline" class="form-element">
          <mat-label>Price</mat-label>
          <input matInput formControlName="triggerPrice" maxlength="10">
        </mat-form-field>
      </div>
    
      <!-- Status Dropdown -->
      <div class="form-row">
        <mat-form-field appearance="outline" class="form-element">
          <mat-label>Status</mat-label>
          <mat-select formControlName="selectedStatus">
            <mat-option value="OPEN">Open</mat-option>
            <mat-option value="CLOSE">Close</mat-option>
          </mat-select>
        </mat-form-field>
      </div>    
    </form>
</mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-flat-button color="primary" [disabled]="tradeDialogForm.invalid" (click)="onTradeSubmit(data.portfolioName)">
      Submit
    </button>
    <button mat-stroked-button color="warn" mat-dialog-close>Close</button>
  </mat-dialog-actions>

