import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { EquityService } from 'src/app/services/equity.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  constructor(private http: HttpClient, private equityService: EquityService,
    private userService: UserService) {
  }
  ngOnInit() {
    this.getPortfolioCount();
    this.getProfiltBookingCount();
    this.displayDateTime();
     if (this.userService.isLoggedIn) {
      this.loggedInUser = this.userService.loggedInUser;
    }
  }

  privatePortCount!: any;
  publicPortCount!: any;
  premiumPortCount!: any;
  profitBookingCount!:any;
  loggedInUser: any = 'Guest';
  dateTime:any;

  getPortfolioCount() {
    this.equityService.getPortfolioCount().subscribe((data: any) => {
      this.premiumPortCount = data.premiumPortCount;
      this.privatePortCount = data.privatePortCount;
      this.publicPortCount = data.publicPortCount;
    })

  }

  getProfiltBookingCount() {
    this.equityService.getProfiltBookingCount().subscribe((data: any) => {
      this.profitBookingCount = data;
    })
  }

  displayDateTime() {
    const updateDateTime = () => {
        const now = new Date();
        this.dateTime = now.toLocaleString();
    };
    updateDateTime();
    setInterval(updateDateTime, 1000);
  }
}
