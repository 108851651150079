import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KiteConnectService } from 'src/app/services/kite-connect.service';
import {Chart} from 'chart.js/auto'
// import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-chain-data',
  templateUrl: './chain-data.component.html',
  styleUrls: ['./chain-data.component.css']
})
export class ChainDataComponent {

  expiryDateList:string[] = [];
  expiryDateSel!:string;
  weekDateList:string[] = [];
  dateSel!:string;

  optionStrikeData:any;
  ceChart:any | undefined;
  peChart:any | undefined;

  ngOnInit(){
    this.loadExpiryDates();
    // this.createChart();
  }

  constructor(private router:Router,private kiteService:KiteConnectService){
    if(!this.hasRoleOf('Admin')){
      this.router.navigateByUrl("login");
   }
  }

  hasRoleOf(role: string) {
    var userRoles = sessionStorage.getItem('userRoles')
    return userRoles != undefined && userRoles.includes(role);
  }

loadExpiryDates(){
  this.kiteService.loadExpiryDates().subscribe((res: string[]) => {
    this.expiryDateList = res;
  });
  }
  
  loadWeekDates(){
    this.kiteService.loadWeekDates(this.expiryDateSel).subscribe((res: string[]) => {
      this.weekDateList = res;
    });
  }


  loadChainData(){
    this.kiteService.loadChainData(this.expiryDateSel,this.dateSel).subscribe((res: any) => {
      this.optionStrikeData = res;
      this.createCEChart();
      this.createPEChart();
    });
  }

  createCEChart(){
    this.ceChart?.destroy();
    this.ceChart = new Chart("MyCEChart", {
      type: 'line', //this denotes tha type of chart
     data:{
      labels: this.optionStrikeData.lineCEChartData.labels, 
      datasets:this.optionStrikeData.lineCEChartData.dataSetList
     },
      options: {
        aspectRatio:2.5
      }      
    });
  }

  createPEChart(){
    this.peChart?.destroy();
    this.peChart = new Chart("MyPEChart", {
      type: 'line', //this denotes tha type of chart
     data:{
      labels: this.optionStrikeData.linePEChartData.labels, 
      datasets:this.optionStrikeData.linePEChartData.dataSetList
     },
      options: {
        aspectRatio:2.5
      }      
    });
  }
}
