import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dilaog:MatDialog) { }

  openConfirmDailog(title:any,msg:any)
  {
    return this.dilaog.open(ConfirmationDialogComponent,
      {
        width:'390px',
        disableClose:true,
        data:{
          title:title,
          message:msg
        }
      });
  }
}
