<footer class="footer">
    <div class="footer-content">
        <div class="footer-brand">
            <!-- <img src="path-to-your-logo.png" alt="logo" class="footer-logo"> -->
            <p>Stock analysis and screening tool</p>
            <p>FinTip © 2024-2025</p>
            <!-- <p>Made with <span class="heart">❤️</span> in India.</p> -->
            <!-- <p>Data provided by Company Data Provider</p> -->
        </div>
        <!-- <div class="footer-links">
            <div class="footer-column">
                <h4>Product</h4>
                <a href="#">Premium</a>
                <a href="#">What's new?</a>
                <a href="#">Learn</a>
            </div>
            <div class="footer-column">
                <h4>Team</h4>
                <a href="#">About us</a>
                <a href="#">Support</a>
            </div>
            <div class="footer-column">
                <h4>Theme</h4>
                <a href="#">Light</a>
                <a href="#">Dark</a>
                <a href="#">Auto</a>
            </div>
        </div> -->
    </div>
    <div class="footer-bottom">
        <!-- <a href="#">Terms & Privacy.</a> -->
    </div>
</footer>
