import {Injectable} from '@angular/core';

@Injectable({
    providedIn:'root'
})

export class UserService{

  constructor(){}

  isLoggedIn=false;
  userId=0;
  userName='';
  loggedInUser='';
  userEmail='';
  kiteLive = false;
  roles:string[]=[];
}