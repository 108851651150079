import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CanactivateGuardComponent implements CanActivate {

  constructor(public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var loggedInUser = sessionStorage.getItem("loggedInUser");
    if (loggedInUser == null) {
      sessionStorage.setItem('redirectUrl', state.url);
      this.router.navigate(["login"]);
      return false;
    }else {
      return true;
    }
  }
}