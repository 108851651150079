<div class="container-body">    
<form [formGroup]="ContactSupportForm">
    <div>
        <mat-form-field appearance="outline" class="form-element" style="width:500px !important">
            <mat-label>Subject</mat-label>
            <input matInput formControlName="subject" >
        </mat-form-field> </div>
        <div>
         <mat-form-field appearance="outline" class="form-element" style="width:500px; height:200px !important">
            <mat-label>Message</mat-label>
            <textarea matInput #inputDesc [maxLength]="200" formControlName="message"></textarea>
            <mat-hint class="info-hint">allowed message {{inputDesc.value.length || 0}}/200</mat-hint>
        </mat-form-field>
    </div>
    <button mat-button [disabled]="ContactSupportForm.invalid"
        (click)="sendEmail()">Submit</button>
        <button mat-button (click)="resetForm()">Reset</button>
</form>
</div>
