import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OptionStrikeData } from 'src/app/model/OptionStrikeData';
import { KiteConnectService } from 'src/app/services/kite-connect.service';

@Component({
  selector: 'app-data-display',
  templateUrl: './data-display.component.html',
  styleUrls: ['./data-display.component.css']
})
export class DataDisplayComponent {

  optionStrikeData:OptionStrikeData[] = [];
  expiryDateList:string[] = [];
  weekDateList:string[] = [];
  expiryDateSel!:string;
  dateSel!:string;
  constructor(private router:Router,private kiteService:KiteConnectService){
    if(!this.hasRoleOf('Admin')){
      this.router.navigateByUrl("login");
   }
  }
  ngOnInit(){
    this.loadExpiryDates();
}

hasRoleOf(role: string) {
  var userRoles = sessionStorage.getItem('userRoles')
  return userRoles != undefined && userRoles.includes(role);
}

loadExpiryDates(){
  this.kiteService.loadExpiryDates().subscribe((res: string[]) => {
    this.expiryDateList = res;
  });
  }
  loadWeekDates(expiryDateSel:any){
    this.kiteService.loadWeekDates(this.expiryDateSel).subscribe((res: string[]) => {
      this.weekDateList = res;
    });
  }

loadStrikeDataByExpiry(){
  this.kiteService.loadStrikeDataByExpiry(this.expiryDateSel,this.dateSel).subscribe((res: OptionStrikeData[]) => {
    this.optionStrikeData = res;
  });
}
}
