<div class="center">    
<div> 
    Reach us for any futher services/demo/suggestions on the fintip application.
    <p></p>
    <div>
    <p>Contact details</p>
       
       <a href="#" routerLinkActive="active" routerLink="/contactus">
       <p style="font-style:oblique;color: rgb(38, 174, 72);"> Email: support&#64;fintip.in </p>
       </a>
       <p> Phone: +91 9948755577 </p>
    </div>
</div>
</div>
