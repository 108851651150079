import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { TradeService } from 'src/app/services/trade.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Favorites } from 'src/app/model/favorites';
import { UserService } from 'src/app/services/user.service';

import { MatDialog } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort,Sort} from '@angular/material/sort';
import { TradedialogComponent } from './tradedialog/tradedialog.component';
import { OrderDetails } from 'src/app/model/orderdetails';
import { StrikeData } from 'src/app/model/strikedata';
import {Option} from 'src/app/model/Option';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { UserProfile } from 'src/app/model/userprofile';
import { Subscriber } from 'src/app/model/subscriber';
import { KiteConnectService } from 'src/app/services/kite-connect.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { EquityService } from 'src/app/services/equity.service';
import { AddWatchlistDialogComponent } from './add-watchlist-dialog/add-watchlist-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Portfolio } from 'src/app/model/portfolio';


@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.css']
})
export class TradeComponent {

  options: FormGroup;
  strikeData!: StrikeData[];
  selectedOptions!: StrikeData[];
  
  newFavorites: string[] = [];
  favorites: Favorites = new Favorites();

  savedWatchObj!: StrikeData[];
  displayedWatchListColumns:string[] = ['tradingsymbol','bs','last_price', 'exchange','actions'];
  watchListdataSource = new MatTableDataSource<StrikeData>([]);
  @ViewChild('watchListpaginator') watchListpaginator!:MatPaginator;
  @ViewChild('watchListSort') watchListSort = new MatSort();

  orderDetails!: OrderDetails[];
  displayedColumns:string[] = ['tradingsymbol','triggerQnty','productType','orderVariety','triggerPrice','last_price','profitLoss','actions'];
  dataSource = new MatTableDataSource<OrderDetails>([]);
  @ViewChild('activeOrderpaginator') activeOrderpaginator!:MatPaginator;
  @ViewChild('activeOrderSort') activeOrderSort = new MatSort();

  limitOrderDetails!: OrderDetails[];
  limitOrderDisplayedColumns:string[] = ['orderId','tradingsymbol','triggerQnty','transactionType','triggerPrice','productType','orderVariety','orderStatus','actions'];
  limitOrderDataSource = new MatTableDataSource<OrderDetails>([]);
  @ViewChild('limitOrderpaginator') limitOrderpaginator!:MatPaginator;
  @ViewChild('limitOrderSort') limitOrderSort=new MatSort();

  completedOrderDetails!: OrderDetails[];
  completedOrderDisplayedColumns:string[] = ['orderId','createdDate','tradingsymbol','triggerQnty','transactionType','triggerPrice','productType','orderVariety','orderStatus','actions'];
  completedOrderDataSource = new MatTableDataSource<OrderDetails>([]);
  @ViewChild('completedOrderpaginator') completedOrderpaginator!:MatPaginator;
  @ViewChild('completedOrderSort') completedOrderSort=new MatSort();
  panelOpenState = false;
  completedPanelOpenState = false;
  constructor(private tradeService: TradeService,  private equityService: EquityService,
    private loginService:LoginService,private subscriberService:SubscriberService,fb: FormBuilder, 
    private userService: UserService, private kiteConnect:KiteConnectService,private dialog: MatDialog,
    private dialogService: DialogService,@Inject(Injector) private readonly injector: Injector,
    private router:Router,private route: ActivatedRoute) {
    this.options = fb.group({
      bottom: 0,
      fixed: false,
      top: 0
    });
  }

  liveKite!:boolean;
  userProfileData:UserProfile = new UserProfile();
  subscriber: Subscriber[] = [];
  activeSubCount!:number;
  totalAmt!:number;

  watchListNames:any=[];
  watchListItemSel!: string;
  searchField!:string;

  portfolioTypeConst:Option[] =  [
    { "name": "Private","value": "private", "checked": true,"disabled":false },
    { "name": "Public", "value": "public",  "checked": false,"disabled":false}];

    proTypeConst:Option[] =  [
      { "name": "Yes","value": "yes", "checked": true, "disabled":false },
      { "name": "No", "value": "no", "checked": false,"disabled":false}];

  ngOnInit(){
    this.loadUserProfile();
}

loadSubscribers() {
  this.subscriberService.getSubscriberList(sessionStorage.getItem('userId')).subscribe((res: Subscriber[]) => {
    this.subscriber = res;
    this.activeSubCount = this.subscriber.filter(item => item.active === true).length;
  });
}

  loadUserProfile(){
    this.loginService.getUserProfileById(sessionStorage.getItem('userId')).subscribe((res:any) => {
      this.userProfileData = res;
      this.getWatchListNames();
      this.getPositionDetails();
      this.getLimitOpenOrders();
      this.getCompletedOrders(); 
      this.loadSubscribers();
    });
  }

  refreshWatchListItems(){
    this.getWatchListItemsByName();
  }


  refreshPositionDetails(){
      this.getPositionDetails();
      this.getCompletedOrders(); 
  }

  refreshLimitOrders(){
    this.getLimitOpenOrders();
  }

  onSlideClick(value:any){
    console.log("isKiteLive::"+value.checked+" userID::"+this.userService.userId);

    this.userProfileData.id = this.userService.userId;
    this.userProfileData.username = this.userService.userName;
    this.userProfileData.email = this.userService.userEmail;
    this.userProfileData.kiteLive = value.checked;
    this.loginService.updateUserProfile(this.userProfileData).subscribe((res:any) => {
    });
  }

  openDialog(orderId:number, transactionType:any, orderStatus:string, tradeItem:StrikeData, 
            operationType:string, 
            defOrderTypeDisable:boolean,defaultOrderType:string,
            defProductTypeDisable:boolean,defProductType:string, 
            defTriggerPrice:number, defTriggerQnty:number, positionQnty:number,userProfile:UserProfile): void {
   
    const nfoVarietyConst:Option[] = [ 
      { "name": "MIS",    "value": "MIS",   "checked": true,"disabled":false },
      { "name": "NRML",   "value": "NRML",  "checked": false,"disabled":false}]; 
    const equityOrderVarietyConst:Option[] = [ 
      { "name": "MIS",    "value": "MIS",   "checked": true,"disabled":false },
      { "name": "CNF",   "value": "CNF",  "checked": false, "disabled":true}]; 
    const productTypeConst:Option[] =  [
      { "name": "Market","value": "MARKET", "checked": true,"disabled":false },
      { "name": "Limit", "value": "LIMIT",  "checked": false,"disabled":false}];             

    console.log("selected lot_size:"+tradeItem.lot_size
    +" defTriggerQnty:"+defTriggerQnty
    +" defaultOrderType:"+defaultOrderType
    + "defProductTypeDisable:"+defProductTypeDisable
    +" defProductType:"+defProductType
    +" operationType:"+operationType
    );

    if(operationType == "EXIT" && positionQnty > 0){
      transactionType = "SELL";
    }else if(operationType == "EXIT" && positionQnty < 0){
      transactionType = "BUY";
    }

    if(operationType == "ADD" && positionQnty > 0){
      transactionType = "BUY";
    }else if(operationType == "ADD" && positionQnty < 0){
      transactionType = "SELL";
    }
   
    const dialogRef = this.dialog.open(TradedialogComponent, {
      data: {
        title: transactionType + ' '+ tradeItem.tradingsymbol,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',
        productType: productTypeConst,
       
        orderVariety : tradeItem.exchange == "NFO" ? nfoVarietyConst:equityOrderVarietyConst,
        defOrderTypeDisable:defOrderTypeDisable,
        defaultOrderType:defaultOrderType,
       
        defProductTypeDisable:defProductTypeDisable,
        defProductType:defProductType,
        
        defTriggerQnty:defTriggerQnty,
        defTriggerPrice:defTriggerPrice,
       
        strikeData:tradeItem,
        operationType:operationType,
        orderId: orderId,
        transactionType: transactionType,
        orderStatus:orderStatus,
        kiteLive:userProfile.kiteLive,
        offlineTrade:userProfile.offlineTrade
      },
    }).afterClosed().subscribe(result=>{
        this.ngOnInit();
    });
  }

 

  searchStrikeData(searchkeyword: string = "") {
    console.log("searchStrikeData::" + searchkeyword);
    let portfolio = this.watchListNames.find((data: any) => data.portfolioName === this.watchListItemSel);
    this.tradeService.searchStrikeData(searchkeyword,this.userService.userId,portfolio.id).subscribe((res: any) => {
      this.strikeData = res;
      console.log("loaded strike search data from service size:" + this.strikeData.length);
    });

  }
  onNgModelChange() {
    console.log("user selection on mat select option::" + this.selectedOptions);
    this.selectedOptions.map((item, index) => {
      console.log("----" + this.selectedOptions[index])    
      this.kiteConnect.getQuoteByInstrument(this.selectedOptions[index].instrument_token).subscribe((res: any) => {
        this.selectedOptions[index].last_price = res;
        if (!this.savedWatchObj.includes(this.selectedOptions[index])) {
          this.savedWatchObj.push(this.selectedOptions[index]);
          //save the data
          this.favorites.agentId = this.userService.userId;
          this.favorites.name = this.selectedOptions[index].name;
          this.favorites.tradingsymbol = this.selectedOptions[index].tradingsymbol;
          this.favorites.instrument_token = this.selectedOptions[index].instrument_token;
          this.favorites.last_price = this.selectedOptions[index].last_price;
          this.favorites.lot_size = this.selectedOptions[index].lot_size;
          this.favorites.instrument_type = this.selectedOptions[index].instrument_type;
          this.favorites.exchange = this.selectedOptions[index].exchange;
          this.favorites.strike = this.selectedOptions[index].strike;
          this.favorites.segment = this.selectedOptions[index].segment;
          this.favorites.expiry = this.selectedOptions[index].expiry;
          this.favorites.portfolioId = 0;  
          this.saveFavoriteStrikes(this.favorites);
        }
      });     
    })
  }

  onStrikeSelection(selectedStrike:StrikeData) {
    console.log("user selection on mat select option::" + selectedStrike);
    let portfolio = this.watchListNames.find((data: any) => data.portfolioName === this.watchListItemSel);
    this.kiteConnect.getQuoteByInstrument(selectedStrike.instrument_token).subscribe((res: any) => {
      // this.tradeDialogForm.get('triggerPrice') ?.setValue(res);
      selectedStrike.last_price = res;

      this.savedWatchObj.push(selectedStrike);
      this.strikeData = [];
      this.favorites.agentId = Number(sessionStorage.getItem('userId'));
      this.favorites.name = selectedStrike.name;
      this.favorites.tradingsymbol = selectedStrike.tradingsymbol;
      this.favorites.instrument_token = selectedStrike.instrument_token;
      this.favorites.last_price = selectedStrike.last_price;
      this.favorites.lot_size = selectedStrike.lot_size;
      this.favorites.instrument_type = selectedStrike.instrument_type;
      this.favorites.exchange = selectedStrike.exchange;
      this.favorites.strike = selectedStrike.strike;
      this.favorites.segment = selectedStrike.segment;
      this.favorites.expiry = selectedStrike.expiry;
      if (portfolio !== null && portfolio !== undefined) {
        this.favorites.portfolioId = portfolio.id;  
      }
      this.saveFavoriteStrikes(this.favorites); 
    });
  
  }

  saveFavoriteStrikes(newFavorite: any) {
    this.tradeService.saveFavoriteStrikes(newFavorite).subscribe((res: any) => {
      console.log("completed saving new favorites:" + this.savedWatchObj.length);
      this.getWatchListItemsByName();
    });
  }

  // getFavoriteStrikes() {
  //   let portfolioDataObj =  this.watchListNames.find((data: any) => data.portfolioName === this.watchListItemSel);
  //   this.tradeService.getFavoriteData(sessionStorage.getItem('userId'),portfolioDataObj.id).subscribe((res: any) => {
  //     this.savedWatchObj = res;   
  //     // this.totalAmt = this.orderDetails[this.orderDetails.length-1]?.totalAmt;
  //     this.watchListdataSource.data = this.savedWatchObj;
  //     this.watchListdataSource.sort = this.watchListSort;
  //     this.watchListdataSource.paginator = this.watchListpaginator;
  //     console.log("getFavoriteData from DB length:" + this.savedWatchObj.length);
  //   });
  // }

  deleteStrike(deleteItem: StrikeData) {
    console.log(deleteItem.tradingsymbol + " item is getting deleted!!!");
    // this.removeItem(deleteItem);
    this.favorites.agentId = Number(sessionStorage.getItem('userId'));
    this.favorites.name = deleteItem.name;
    this.favorites.tradingsymbol = deleteItem.tradingsymbol;
    this.tradeService.deleteFavoriteStrikes(this.favorites).subscribe((res: any) => {
      console.log("deleted successfully selected item:" + deleteItem.tradingsymbol);
      // this.getFavoriteStrikes();
      this.getWatchListItemsByName();
    }); 
  
  }

  removeItem(deleteItem:StrikeData){
    this.savedWatchObj = this.savedWatchObj.filter(item => item !== deleteItem);
 }


  getPositionDetails(){
    console.log("getPositionDetails:getting order details for positions tab on load:::");    
    this.tradeService.getPositionDetails(sessionStorage.getItem('userId')).subscribe((res: any) => {
        this.orderDetails = res;
        // this.totalAmt = this.orderDetails[this.orderDetails.length-1]?.totalAmt;
        this.totalAmt =  this.orderDetails.map(t => t.totalProfitLoss).reduce((acc, value) => acc + value, 0);
        this.dataSource.data = this.orderDetails;
        this.dataSource.sort = this.activeOrderSort;
        this.dataSource.paginator = this.activeOrderpaginator;
    });
  }

  getLimitOpenOrders(){
    console.log("getting getLimitOpenOrders details on load:::");   
    this.tradeService.getLimitOpenOrders(sessionStorage.getItem('userId')).subscribe((res: any) => {
        this.limitOrderDetails = res;
        this.limitOrderDataSource.data =  this.limitOrderDetails;
        this.limitOrderDataSource.sort = this.limitOrderSort;
        this.limitOrderDataSource.paginator = this.limitOrderpaginator;
    });
  }

  getCompletedOrders(){
    console.log("getting getLimitOpenOrders details on load:::");
   this.tradeService.getCompletedOrders(sessionStorage.getItem('userId')).subscribe((res: any) => {
        this.completedOrderDetails = res;
        this.completedOrderDataSource.data = this.completedOrderDetails;
        this.completedOrderDataSource.sort = this.completedOrderSort;
        this.completedOrderDataSource.paginator = this.completedOrderpaginator;
    });
  }

  applyFilter(event:Event){

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  
  applyLimitOrderFilter(event:Event){

    const filterValue = (event.target as HTMLInputElement).value;
    this.limitOrderDataSource.filter = filterValue.trim().toLowerCase();
    if(this.limitOrderDataSource.paginator){
      this.limitOrderDataSource.paginator.firstPage();
    }
  }

  applyCompletedOrderFilter(event:Event){

    const filterValue = (event.target as HTMLInputElement).value;
    this.completedOrderDataSource.filter = filterValue.trim().toLowerCase();
    if(this.completedOrderDataSource.paginator){
      this.completedOrderDataSource.paginator.firstPage();
    }
  }
  

  addPortfolioNameDialogOpen(action:any, customTitle:any) {

    let portfolio = null;
    if(action == 'rename'){
      portfolio =  this.watchListNames.find((data: any) => data.portfolioName === this.watchListItemSel); 
    }
   
    const dialogRef = this.dialog.open(AddWatchlistDialogComponent, {
      data: {
        title: customTitle,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',       
        action:action,
        portfolio: portfolio
      },
    }).afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  
  getWatchListNames(){
    console.log("this.userService.userId:"+sessionStorage.getItem('userId')+",this.userService.userId"+this.userService.userEmail);
    this.equityService.getWatchListNames(sessionStorage.getItem('userId')).subscribe((res: any) => {
      this.watchListNames = res;      
      console.log("getWatchListNames::"+this.watchListNames[0]);
      if(this.watchListNames?.length){
        if(sessionStorage.getItem('watchListItemSession') == null){
          this.watchListItemSel  = this.watchListNames && this.watchListNames.length ? this.watchListNames[0].portfolioName : '';
          sessionStorage.setItem('watchListItemSession', this.watchListItemSel );
        }else{
          const sessionValue = sessionStorage.getItem('watchListItemSession');
          if (sessionValue !== null) {
            this.watchListItemSel = sessionValue;
          }          
        }    
        this.getWatchListItemsByName();
    }
    });
  }

  getWatchListItemsByName(){
    let portfolioDataObj =  this.watchListNames.find((data: any) => data.portfolioName === this.watchListItemSel);
     if(portfolioDataObj !== null && portfolioDataObj !== undefined){
     sessionStorage.setItem('watchListItemSession', this.watchListItemSel);
     this.tradeService.getFavoriteData(sessionStorage.getItem('userId'),portfolioDataObj.id).subscribe((res: any) => {    
      this.savedWatchObj = res;   
      this.watchListdataSource.data = this.savedWatchObj;
      this.watchListdataSource.sort = this.watchListSort;
      this.watchListdataSource.paginator = this.watchListpaginator;
      console.log("getFavoriteData from DB length:" + this.savedWatchObj.length);
    });
   }
  }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  deleteWatchList(portfolioName:any) {
    if(portfolioName == "" || portfolioName == undefined || portfolioName == null){
      this.toast.info('please select the portfolio');
    }else{
    let portfolio = this.watchListNames.find((data: any) => data.portfolioName === portfolioName);
    this.dialogService.openConfirmDailog("deletion", "Are you sure you want to delete " + portfolio.portfolioName + " portfolio and content of it ?")
    .afterClosed()
    .subscribe(res => {
      if (res) {
        let deletePortObj:Portfolio = new Portfolio;
        deletePortObj.agentId = Number(sessionStorage.getItem('userId'));
        deletePortObj.id = portfolio.id;
        deletePortObj.portfolioName = portfolio.portfolioName;
        deletePortObj.portfolioValue = portfolio.portfolioName;
        this.equityService.deleteWatchList(deletePortObj).subscribe(data => {
          this.toast.success('Portfolio deleted Successfully!');
          sessionStorage.removeItem('watchListItemSession');
          // this.ngOnInit();
          this.getWatchListNames();
        })
      }
    })
  }
  }

  searchStrikes(){
    if (this.searchField.length == 0) {
      this.strikeData = [];
    } else if (this.searchField.length > 2 && this.savedWatchObj.length < 50) {
        this.searchStrikeData(this.searchField);
    }    
  }

  clearSearchField(){
    this.searchField = "";
    this.strikeData = [];
  }

  public showLimited:boolean = true;
  public buttonName:any = 'Show';

  public showCompleted:boolean = false;

  toggleTradeTable() {
    this.showLimited = !this.showLimited;
  }

  toggleCompleteTradeTable() {
    this.showCompleted = !this.showCompleted;
  }


 

}
