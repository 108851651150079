export class Subscriber {
     _id!:number;;	
    name!:string;
    userName!:string;
    apikey!:string;
    apisecret!:string;
    requestToken!:string;
    accessToken!:string;
    publicToken!:string;
    email!:string;
    tradeTenant!:string;
    active!:boolean;   
    agentId!:number;
    
}
