<div class="container-body">
       <div>
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>Trade Overview</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form class="row g-3">
                       <div class="col-md-4">
                          <label for="inputKite" class="form-label">Kite Live : </label>
                          <span *ngIf="userProfileData.kiteLive">{{userProfileData.kiteLive}}</span>
                          <button *ngIf="!userProfileData.kiteLive" mat-button color="primary" routerLink="/products">Subscribe kiteLive</button>
                      </div>
                      <div class="col-md-4">
                        <label for="inputKite" class="form-label">Offline Trade : </label>
                        <span *ngIf="userProfileData.offlineTrade">{{userProfileData.offlineTrade}}</span>
                        <button *ngIf="!userProfileData.offlineTrade" mat-button color="primary" routerLink="/products">Subscribe offline Trade</button>
                      </div>
                      <div class="col-md-4">
                        <label for="inputSubscriber" class="form-label">Subscribers : </label>
                        <span>{{activeSubCount}}/{{subscriber.length}}</span>
                      </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
                            
              <mat-tab-group>
                <mat-tab label="Watchlist">
                  <div class="mat-elevation-z8">
                    <p>&nbsp;</p>
                    <mat-form-field appearance="outline" class="form-element">
                      <mat-label>Select Portfolio</mat-label>
                      <mat-select [(ngModel)]="watchListItemSel" name="watchListItemSel" class="form-element"
                        (selectionChange)="getWatchListItemsByName()">
                        <mat-option *ngFor="let agentPortfolio of watchListNames" [value]="agentPortfolio.portfolioValue">
                          {{agentPortfolio.portfolioName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item color="primary" (click)="addPortfolioNameDialogOpen('add','Create Watchlist')"
                        >
                        <mat-icon>library_add</mat-icon>Create watchlist</button>
                        <button mat-menu-item color="primary" *ngIf="watchListNames.length > 0" (click)="addPortfolioNameDialogOpen('rename','Rename Watchlist')"
                        >
                        <mat-icon>create</mat-icon>Rename watchlist</button>
                        <button mat-menu-item color="warn" *ngIf="watchListNames.length > 0" (click)="deleteWatchList(watchListItemSel)"
                          >
                          <mat-icon>delete_sweep</mat-icon>Delete portfolio</button>
                      </mat-menu>
                      <button mat-button [matMenuTriggerFor]="menu" style="float: right;">                  
                          <mat-icon>settings</mat-icon>settings
                      </button>

                  <mat-form-field>
                    <mat-icon matPrefix [inline]="true">search</mat-icon>
                    <input matInput placeholder="search and add scrip" autocomplete="off"  [(ngModel)]="searchField" 
                    (keyup)="searchStrikes()">
                    <button mat-button matSuffix (click)="clearSearchField()" *ngIf="searchField">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <button matTooltip="Refresh" color="primary" mat-button (click)="refreshWatchListItems()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                  <mat-selection-list #strike [(ngModel)]="selectedOptions">
                      <mat-option *ngFor="let strike of strikeData" [value]="strike" (click)="onStrikeSelection(strike)">
                        <p class="alignleft"> {{strike.tradingsymbol}}  |  {{strike.exchange}} </p>
                      </mat-option>                   
                  </mat-selection-list>
      
                  <table mat-table [dataSource]="watchListdataSource" matSort #watchListSort="matSort" class="mat-elevation-z8">

                    <ng-container matColumnDef="tradingsymbol">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Trading Symbol</th>
                      <td mat-cell *matCellDef="let element"> {{element.tradingsymbol}} </td>
                    </ng-container>

                    <ng-container matColumnDef="bs">
                      <th mat-header-cell *matHeaderCellDef> B/S</th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-button color="primary" (click)="openDialog(0,'BUY', '', element,  'BUY', false, 'MIS', false, 'MARKET',element.last_price,element.lot_size,0,userProfileData)">BUY</button>
                        <button mat-button color="warn" (click)="openDialog(0,'SELL','', element,  'SELL', false, 'MIS',  false, 'MARKET',element.last_price,element.lot_size,0,userProfileData)">SELL</button>
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="last_price">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> LTP </th>
                      <td mat-cell *matCellDef="let element"> {{element.last_price}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="exchange">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Exchange </th>
                      <td mat-cell *matCellDef="let element"> {{element.exchange}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-button color="warn" (click)="deleteStrike(element)" matTooltip="delete scrip"><mat-icon class="item-delete-icon">delete</mat-icon></button>
                      </td>
                    </ng-container>                
                  
                    <tr mat-header-row *matHeaderRowDef="displayedWatchListColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedWatchListColumns;"></tr>
                  </table>
              </div>
                </mat-tab>
                <mat-tab label="Positions ({{orderDetails != undefined ? orderDetails.length : 0}})">
                  <div class="mat-elevation-z8">
                  <mat-label>
                      <mat-icon>search</mat-icon>
                  </mat-label>  
                  <mat-form-field appearance="outline" class="example-form-field">
                      <mat-label>Search</mat-label>
                      <input type="text" matInput (keyup)="applyFilter($event)" #input>
                  </mat-form-field>
                  <button matTooltip="Refresh" color="primary" mat-button (click)="refreshPositionDetails()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                  
                    <table mat-table [dataSource] = "dataSource"  matSort  #activeOrderSort="matSort" >
                      <ng-container matColumnDef="tradingsymbol">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tradingsymbol"> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.strikeData.tradingsymbol}} </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                      </ng-container>
  
                      <ng-container matColumnDef="triggerQnty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Qnty"> Qnty </th>
                        <td mat-cell *matCellDef="let element"> {{element.triggerQnty}} </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                      </ng-container>
                  
                      <ng-container matColumnDef="productType">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Product" > ProductType </th>
                          <td mat-cell *matCellDef="let element"> {{element.productType}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                      <ng-container matColumnDef="orderVariety">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ProductType" > Order Variety </th>
                          <td mat-cell *matCellDef="let element"> {{element.orderVariety}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="triggerPrice">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by triggerPrice" > Avg </th>
                          <td mat-cell *matCellDef="let element"> {{element.triggerPrice}} </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <ng-container matColumnDef="last_price">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last_price" > LTP </th>
                          <td mat-cell *matCellDef="let element"> {{element.strikeData.last_price}} </td>
                          <td mat-footer-cell *matFooterCellDef>Total </td>

                        </ng-container>
                        <ng-container matColumnDef="profitLoss">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by profitLoss" > P&L </th>
                          <td mat-cell *matCellDef="let element"> {{element.totalProfitLoss}} </td>
                          <td mat-footer-cell *matFooterCellDef>{{totalAmt | currency:'INR'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef>Action</th>
                          <td mat-cell *matCellDef="let element">
                            
                            <button *ngIf="element.triggerQnty != 0" mat-raised-button color="warn" (click)="openDialog(element._id,'EXIT', element.orderStatus, element.strikeData, 'EXIT', false,element.orderVariety, false,element.productType, element.strikeData.last_price, element.triggerQnty,element.triggerQnty,userProfileData)">Exit</button>  
                            <button *ngIf="element.triggerQnty != 0" mat-raised-button color="primary" (click)="openDialog(element._id,'ADD', element.orderStatus, element.strikeData, 'ADD', false,element.orderVariety, false,element.productType, element.strikeData.last_price, element.strikeData.lot_size,element.triggerQnty,userProfileData)">Add</button>   
                            <button *ngIf="element.triggerQnty == 0" mat-raised-button color="primary" (click)="openDialog(element._id,'BUY', element.orderStatus, element.strikeData, 'BUY', false,element.orderVariety, false,element.productType, element.strikeData.last_price, element.strikeData.lot_size,element.triggerQnty,userProfileData)">Buy</button>
                            <button *ngIf="element.triggerQnty == 0" mat-raised-button color="warn" (click)="openDialog(element._id,'SELL', element.orderStatus, element.strikeData, 'SELL', false,element.orderVariety, false,element.productType, element.strikeData.last_price, element.strikeData.lot_size,element.triggerQnty,userProfileData)">Sell</button>                            
                          </td>
                          <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    </table> 
                    <mat-paginator [pageSizeOptions]="[10, 15, 20]" #activeOrderpaginator
                    showFirstLastButtons
                    aria-label="Select page of periodic elements">
                  </mat-paginator>
                  </div>  
                  <mat-divider></mat-divider>


                  <div class="mat-elevation-z8">
                  <mat-accordion>
                    <mat-expansion-panel (opened)="completedPanelOpenState = true" (closed)="completedPanelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>                              
                                <span>Completed Orders ({{completedOrderDetails!= undefined ? completedOrderDetails.length : 0}})</span>
                            </mat-panel-title>
                    </mat-expansion-panel-header>               
                  
                      <span></span>
                      <mat-label>
                          <mat-icon>search</mat-icon>
                        </mat-label>  
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label>Search</mat-label>
                          <input matInput (keyup)="applyCompletedOrderFilter($event)" #inputComplete>
                         </mat-form-field>
                      
                        <table mat-table [dataSource] = "completedOrderDataSource" matSort #completedOrderSort="matSort">                    
                          <ng-container matColumnDef="orderId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> OrderId </th>
                            <td mat-cell *matCellDef="let element"> {{element._id}} </td>
                          </ng-container>
                          <ng-container matColumnDef="createdDate">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by CreatedDate"> createdDate </th>
                              <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
                            </ng-container>
  
                          <ng-container matColumnDef="tradingsymbol">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tradingsymbol"> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.strikeData.tradingsymbol}} </td>
                          </ng-container>
  
                          <ng-container matColumnDef="triggerQnty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Qnty"> Qnty </th>
                            <td mat-cell *matCellDef="let element"> {{element.triggerQnty}} </td>
                          </ng-container>
                      
                          <ng-container matColumnDef="transactionType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by transactionType" > Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
                          </ng-container>
                          <ng-container matColumnDef="triggerPrice">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by TriggerPrice" > TriggerPrice </th>
                              <td mat-cell *matCellDef="let element"> {{element.triggerPrice}} </td>
                            </ng-container>
                           <ng-container matColumnDef="productType">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Product" > ProductType </th>
                              <td mat-cell *matCellDef="let element"> {{element.productType}} </td>
                            </ng-container>
                          <ng-container matColumnDef="orderVariety">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ProductType" > Order Variety </th>
                              <td mat-cell *matCellDef="let element"> {{element.orderVariety}} </td>
                            </ng-container>
                          <ng-container matColumnDef="orderStatus">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email" > OrderStatus </th>
                              <td mat-cell *matCellDef="let element"> {{element.orderStatus}} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                              <th mat-header-cell *matHeaderCellDef></th>
                              <td mat-cell *matCellDef="let element"> 
                                  <!-- <button (click)="openDialog(element.transactionType, element.tradingName, 'MODIFYLIMIT')">Modify</button>    
                                  <button (click)="openDialog(element.transactionType, element.tradingName, 'CANCELLIMIT')">Cancel</button>                           -->
                              </td>
                             
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="completedOrderDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: completedOrderDisplayedColumns;"></tr>
                        </table>               
                        <mat-paginator [pageSizeOptions]="[10, 15, 20]" #completedOrderpaginator
                        showFirstLastButtons
                        aria-label="Select page of periodic elements">
                      </mat-paginator>
                      </mat-expansion-panel>
                      </mat-accordion>
                      </div>
                </mat-tab>
                
                <mat-tab label="Limit Orders ({{limitOrderDetails!= undefined ? limitOrderDetails.length : 0}})"> 
                                
                <div *ngIf="showLimited" class="mat-elevation-z8">
                 
                    <mat-label>
                        <mat-icon>search</mat-icon>
                      </mat-label>  
                    <mat-form-field appearance="outline" class="form-element">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyLimitOrderFilter($event)" #inputLimit>
                    </mat-form-field>
                    <button matTooltip="Refresh" color="primary" mat-button (click)="refreshLimitOrders()">
                      <mat-icon>refresh</mat-icon>
                    </button>
                      <table mat-table [dataSource] = "limitOrderDataSource" matSort  #limitOrderSort="matSort" >
                        <ng-container matColumnDef="orderId">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"> OrderId </th>
                          <td mat-cell *matCellDef="let element"> {{element._id}} </td>
                        </ng-container>
                        <ng-container matColumnDef="tradingsymbol">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.strikeData.tradingsymbol}} </td>
                        </ng-container>

                        <ng-container matColumnDef="triggerQnty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Qnty"> Qnty </th>
                          <td mat-cell *matCellDef="let element"> {{element.triggerQnty}} </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="transactionType">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by transactionType" > Type </th>
                          <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
                        </ng-container>
                        <ng-container matColumnDef="triggerPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by TriggerPrice" > TriggerPrice </th>
                            <td mat-cell *matCellDef="let element"> {{element.triggerPrice}} </td>
                          </ng-container>
                         <ng-container matColumnDef="productType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Product" > ProductType </th>
                            <td mat-cell *matCellDef="let element"> {{element.productType}} </td>
                          </ng-container>
                        <ng-container matColumnDef="orderVariety">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ProductType" > Order Variety </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderVariety}} </td>
                          </ng-container>
                        <ng-container matColumnDef="orderStatus">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email" > OrderStatus </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderStatus}} </td>
                          </ng-container>
                          <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element"> 
                                <button mat-raised-button color="primary" (click)="openDialog(element._id,element.transactionType, element.orderStatus, element.strikeData, 'MODIFYLIMIT', true, element.orderVariety, false,element.productType,element.triggerPrice, element.triggerQnty,element.triggerQnty,userProfileData)">Modify</button>    
                                <button mat-raised-button color="warn" (click)="openDialog(element._id,element.transactionType, element.orderStatus, element.strikeData, 'CANCELLIMIT', true, element.orderVariety, true, element.productType,element.triggerPrice,element.triggerQnty,element.triggerQnty,userProfileData)">Cancel</button>                          
                            </td>
                           
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="limitOrderDisplayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: limitOrderDisplayedColumns;"></tr>
                      </table>               
                      <mat-paginator [pageSizeOptions]="[10, 15, 20]" #limitOrderpaginator
                      showFirstLastButtons
                      aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>

               
                
                </mat-tab>
                 </mat-tab-group>
                <mat-divider></mat-divider>     
  

                </div>