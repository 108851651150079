<div mat-dialog-title class="dialog-title">
  Subscriber Pending Approvals
  <span class="close">
      <mat-icon mat-dialog-close>close-icon</mat-icon>
  </span>
</div>
<mat-divider></mat-divider>
<div>
  <mat-table [dataSource]="subscriberPendingSource" matSort #subscriberListSort="matSort">
      <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdDate"> Created Date </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.createdDate}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subscriberId">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SubscriberId"> SubscriberId </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subscriberId}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subscriberName">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SubscriberName"> SubName </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subscriberName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
          <mat-cell *matCellDef="let element">
              <button class="approve-button" (click)="submitPortfolioApprovals(element, 'Active')" matTooltip="Approve request"></button>
              <button class="reject-button" (click)="submitPortfolioApprovals(element, 'Rejected')" matTooltip="Reject request"></button>
          </mat-cell>
      </ng-container>
      <ng-container matColumnDef="">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>       
    </ng-container>
      <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
      <mat-row *matRowDef="let row; columns: getDisplayedColumns();"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 15, 20]" #subscriberListPaginator showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
</div>
