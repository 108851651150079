<div mat-dialog-title [style.background-color]="titleColor">
  {{ data.title }} 
  <span class="close">
      <mat-icon mat-dialog-close>close-icon</mat-icon>
  </span>
</div>
<mat-dialog-content>
  <!-- <p>{{ data.message }}</p> -->
  <mat-divider></mat-divider>  
  <mat-radio-group [(ngModel)]="orderVarietyRadioSel" [disabled]="isOrderVarietyDisabled">
    <mat-radio-button *ngFor="let option of data.orderVariety" [value]="option.value" [checked]="option.checked" [disabled]="option.disabled">
      {{ option.name }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-divider></mat-divider>

  <mat-form-field appearance="outline" class="form-element">
    <mat-label>Qnty - (lot size : {{data.strikeData.lot_size}})</mat-label>
    <input matInput [(ngModel)]="triggerQnty" value="{{triggerQnty}}" [disabled]="data.operationType == 'CANCELLIMIT'" >   
    <mat-hint class="error-hint" *ngIf="triggerQnty%data.strikeData.lot_size!=0">
      Qnty is multple of lot size {{data.strikeData.lot_size}}
    </mat-hint>
 </mat-form-field>


<mat-form-field appearance="outline" class="form-element">
  <mat-label>Price</mat-label>
  <input matInput [(ngModel)]="triggerPrice" [disabled]="productTypeRadioSel !== 'LIMIT' || data.operationType == 'CANCELLIMIT'">
  <mat-hint class="error-hint" *ngIf="productTypeRadioSel == 'LIMIT' && triggerPrice <= 0">
    Price needed for Limit order.
  </mat-hint>
</mat-form-field>

 
  <mat-divider></mat-divider>
  <mat-radio-group [(ngModel)]="productTypeRadioSel" [disabled]='isProductTypeDisabled'>
    <mat-label>Product Type:</mat-label>
    <mat-radio-button *ngFor="let option of data.productType" [value]="option.value" [checked]="option.checked">
        {{ option.name}}
    </mat-radio-button>     
  </mat-radio-group>  
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions>
  <button mat-button mat-dialog-submit  (click)="onTradeSubmit(data.operationType)">Submit</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>

