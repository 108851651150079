const apiUrl = 'https://backend.fintip.in/fintip-service-0.0.1-SNAPSHOT';
export const environment = {
    serverApiUrl:apiUrl,
    mfeApiUrl:'https://fintip.in',
    tradeServiceUrl:  apiUrl+'/api/trade',
    dashboardServiceUrl: apiUrl+'/api/dashboard',
    equityServiceUrl: apiUrl+'/api/equity',
    userServiceUrl:   apiUrl+'/api/auth',
    userProfileServiceUrl: apiUrl+'/api/userProfile',
    subscriberServiceUrl: apiUrl+'/api/subscriber',
    refreshTokenUrl: apiUrl+'/api/auth/refresh-token',
    kiteConnectUrl:apiUrl+'/api/fintip/kite',
    optionServicetUrl:apiUrl+'/api/fintip/options',
    emailServiceUrl: apiUrl+'/api/email'
}