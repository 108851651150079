<div class="container-body">
  
  <div class="row">
    <div class="col-md-12 grid-margin">
      <div class="row">
        <div class="col-12 col-xl-8 mb-4 mb-xl-0">
          <h3 class="font-weight-bold">Welcome {{loggedInUser}}</h3>
          <!-- <h6 class="font-weight-normal mb-0">All systems are running smoothly! You have <span class="text-primary">3 unread alerts!</span></h6> -->
        </div>
        <div class="col-12 col-xl-4">
         <div class="justify-content-end d-flex">
             {{dateTime}}
         </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">      
        <div class="col-md-6">
          <div class="card tale-bg">
              <div class="topright">
                <h1>NIFTY</h1>
              </div>
              <img src="/../../assets/images/cartoon.jpg" alt="people">              
          </div>
        </div>
        <div class="col-md-6 grid-margin transparent">
          <div class="row">
            <div class="col-md-6 mb-4 stretch-card">
              <div class="card card-tale">
                <div class="card-body">
                  <p class="mb-4">Profit Bookings</p>
                  <h2>{{profitBookingCount}}</h2>
                  <p>More than 10.00% (from last 360 days)</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4 stretch-card">
              <div class="card card-dark-blue">
                <a href="#" routerLinkActive="active"
                  routerLink="/equity" [queryParams]= "{selectedTab:1}">
                <div class="card-body">
                  <p class="mb-4">Premium Portfolios</p>
                  <h2>{{premiumPortCount}}</h2>
                  <p>Only Premium - BUY and SELL</p>
                </div></a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-4 stretch-card">
              <div class="card card-dark-blue">
                <a href="#" routerLinkActive="active"
                  routerLink="/equity" [queryParams]= "{selectedTab:2}">
                <div class="card-body">
                  <p class="mb-4">Public Portfolios</p>
                 <h2>{{publicPortCount}}</h2>
                  <p>Premium and Regular</p>
                </div></a>
              </div>
            </div>
            <div class="col-md-6 mb-4 mb-lg-0 stretch-card">
             
              <div class="card card-light-blue">
                <a href="#" routerLinkActive="active"
                routerLink="/equity" [queryParams]= "{selectedTab:0}">
                <div class="card-body">
                  <p class="mb-4">Private Portfolios</p>
                  <h2>{{privatePortCount}}</h2>
                  <p>Exclusively personal</p>
                </div></a>
              </div>
            </div>           
          </div>
        </div>
      </div>
     
     
     <!-- <div class="chart-container">
        <canvas  id="MyChart" >{{ chart }}</canvas>
    </div>
      -->
  
       <!-- Service Start -->
    <div class="container-xxl py-5">
      <div class="container py-5">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h1 class="mb-5">Explore Our Services</h1>
          </div>
          <div class="row g-4">
              <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                  <div class="service-item p-4">
                      <div class="overflow-hidden mb-4">
                          <img class="img-fluid" src="/../../assets/images/portfolio-management.jpg" alt="">
                      </div>
                      <h4 class="mb-3">Portfolio Management</h4>
                      <p>We help to build financial portfolio personally.</p>
                      <a class="btn-slide mt-2" href=""><i class="fa fa-arrow-right"></i><span>Read More</span></a>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                  <div class="service-item p-4">
                      <div class="overflow-hidden mb-4">
                          <img class="img-fluid" src="/../../assets/images/build-recommendations.jpg" alt="">
                      </div>
                      <h4 class="mb-3">Build Trade Watchlists</h4>
                      <p>Fintip is a platform which allows users to create virtual watchlists/portfolios for educational purpose and shared.</p>
                      <a class="btn-slide mt-2" href=""><i class="fa fa-arrow-right"></i><span>Read More</span></a>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                  <div class="service-item p-4">
                      <div class="overflow-hidden mb-4">
                          <img class="img-fluid" src="/../../assets/images/paper-trading.jpeg" alt="">
                      </div>
                      <h4 class="mb-3">Virtual Trade</h4>
                      <p>'Learning is first' and upgrade the trading skills with the zero investment. scale the performance with the reports.</p>
                      <a class="btn-slide mt-2" href=""><i class="fa fa-arrow-right"></i><span>Read More</span></a>
                  </div>
              </div>
             
          </div>
      </div>
  </div>
  <!-- Service End -->
      
      <div class="headerBoxCenter">
      <h2>Frequently Asked Questions</h2>
      </div>
     <div class="row">
      <div class="col-md-6 mb-4">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Who can create the Portfolios?
            </mat-panel-title>                  
          </mat-expansion-panel-header>
          Anyone can create the portfolios with the flavour of private, public.These portfolios are meant for purely educational purpose and can not be sold.
          </mat-expansion-panel>
        </div>
      <div class="col-md-6 mb-4">
        <mat-expansion-panel>
          <mat-expansion-panel-header>  
            <mat-panel-title>
              Does fintip take responsibility for the trade recommendations from any of the portfolio?
            </mat-panel-title>                  
          </mat-expansion-panel-header>
          NO. as these portfolios are meant for educational purpose and subscribers can create recommendations. It's a trade-off between portfolio frequency, accuracy level of individual portfolios.
          </mat-expansion-panel>
      </div>
        <div class="col-md-6 mb-4">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Does any portfolio product guarantee consistent returns?
              </mat-panel-title>                  
            </mat-expansion-panel-header>
            As all know, performance is based on the individual entities and the market condition. 
            Subscriber must validate individual portfolio performance and get validated with certified analyst.
            </mat-expansion-panel>
        </div>
        <div class="col-md-6 mb-4">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Can I sell my portfolio on fintip?
            </mat-panel-title>                  
          </mat-expansion-panel-header>
          NO. SEBI certified analysts can create portfolios and sell on fintip. please reach us on further discussion.
          </mat-expansion-panel>
          </div>
          <div class="col-md-6 mb-4">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
               What type of portfolios can be shared ?
              </mat-panel-title>                  
            </mat-expansion-panel-header>
           All sort of (private, public) portfolios can be shared via copy link.
            </mat-expansion-panel>
          </div>
           <div class="col-md-6 mb-4">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Can offline trade having report building/generation ?
                  </mat-panel-title>                  
                </mat-expansion-panel-header>
                Absolutely, trader can verify the performance of the particular period from the reports.
                </mat-expansion-panel>
              </div>
      </div>              

</div>