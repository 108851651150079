import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { emailDetails } from 'src/app/model/emailDetails';
import { EquityService } from 'src/app/services/equity.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent {

  @ViewChild(FormGroupDirective)
  formDirective!:FormGroupDirective;

  constructor(private router: Router,
    @Inject(Injector) private readonly injector: Injector,
     private equityService: EquityService) {
  }

  emailDetails: emailDetails = new emailDetails();
  ContactSupportForm = new FormGroup(
    {
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required])
    }
  );

  private get toast() {
    return this.injector.get(ToastrService);
  }

  resetForm(){
    this.formDirective.resetForm();
  }

  sendEmail() {
    this.emailDetails.subject = this.ContactSupportForm.value.subject != undefined ? this.ContactSupportForm.value.subject : '';
    this.emailDetails.msgBody = this.ContactSupportForm.value.message != undefined ? this.ContactSupportForm.value.message : '';
    this.emailDetails.sender = sessionStorage.getItem("loggedInUser");
    this.emailDetails.recipient = "support@fintip.in";
    
    this.equityService.sendMail(this.emailDetails).subscribe((res: any) => {
     this.toast.info(res);
     this.formDirective.resetForm();
    }, (error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.toast.error('Technical issue:', error.error);
      } else if (error.status === 400) {
        this.toast.error(error.error);
      }
    });
  } 

  }

