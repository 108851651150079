import { ChangeDetectorRef, Component, Inject, Injector, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Favorites } from 'src/app/model/favorites';
import { StrikeData } from 'src/app/model/strikedata';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { TradeService } from 'src/app/services/trade.service';
import { EquitydailogComponent } from './equitydailog/equitydailog.component';
import { EquityService } from 'src/app/services/equity.service';
import { EquityOrderDetails } from 'src/app/model/equityOrderDetails';
import { DialogService } from 'src/app/services/dialog.service';
import { AddStockDialogComponent } from './add-stock-dialog/add-stock-dialog.component';
import { AddPortfolioDialogComponent } from './add-portfolio-dialog/add-portfolio-dialog.component';
import { Portfolio } from 'src/app/model/portfolio';
import { ToastrService } from 'ngx-toastr';
import { Option } from 'src/app/model/Option';
import { environment } from 'src/environments/environment';
import { Product } from 'src/app/model/Product';
import { PortfolioSubscription } from 'src/app/model/PortfolioSubscription';
import { ViewPortSubscriberComponent } from './view-port-subscriber/view-port-subscriber.component';

@Component({
  selector: 'app-equity',
  templateUrl: './equity.component.html',
  styleUrls: ['./equity.component.css']
})
export class EquityComponent {

  strikeData!: StrikeData[];
  selectedOptions!: StrikeData[];
  savedWatchObj!: StrikeData[];
  newFavorites: string[] = [];
  favorites: Favorites = new Favorites();
  selectedTab!:any;

  proPortifolios!:Portfolio[];
  agentPortifolios:any=[];
 
 
  userProductList!:Product[];
  userPortfolioSubscriptionList!:PortfolioSubscription[];

  freeSubscriptionPortifolios!:Portfolio[];
  freeSubscriptionPortifoliosSource = new MatTableDataSource<Portfolio>([]);
  @ViewChild('freeSubPortfolioPaginator') freeSubPortfolioPaginator!: MatPaginator;
  @ViewChild('freeSubPortfoliosort') freeSubPortfoliosort = new MatSort();
  freeSubColumnDefinitions = [
    { def: "portfolioName", label: "Name", visible: true },
    { def: "description", label: "Description", visible: true },
    { def: "category", label: "category", visible: true },
    { def: "portfolioType", label: "portfolioType", visible: true }
    // { def: "actions", label: "actions", visible: true }   
  ];

  subscribedPortfolios!:Portfolio[];
  subscribedPortfoliosSource = new MatTableDataSource<Portfolio>([]);
  @ViewChild('subscribedPortfoliosPaginator', { static: false }) subscribedPortfoliosPaginator!: MatPaginator;
  @ViewChild('subscribedPortfoliossort', { static: false }) subscribedPortfoliossort = new MatSort();
  subscribedColumnDefinitions = [
    { def: "portfolioName", label: "Name", visible: true },
    { def: "description", label: "Description", visible: true },
    { def: "category", label: "category", visible: true },
    { def: "portfolioType", label: "portfolioType", visible: true }
    // { def: "actions", label: "actions", visible: true }   
  ];

  renewalPortifolios!:Portfolio[];
  loggedUserId!:any;
  portfolioLinkToShare!:any;

  orderDetails!: EquityOrderDetails[];
  displayedColumns: string[] = ['createdDate', 'tradingsymbol', 'transactionType', 'triggerPrice',
    'lastPrice', 'exitPrice', 'exitDate',
    'returnPercent', 'orderStatus', 'actions'];
  columnDefinitions = [
    { def: "createdDate", label: "CreatedDate", visible: true },
    { def: "tradingsymbol", label: "tradingsymbol", visible: true },
    { def: "transactionType", label: "transactionType", visible: true },
    { def: "triggerPrice", label: "triggerPrice", visible: true },
    { def: "lastPrice", label: "lastPrice", visible: true },
    { def: "exitPrice", label: "exitPrice", visible: true },
    { def: "exitDate", label: "exitDate", visible: true },
    { def: "returnPercent", label: "returnPercent", visible: true },
    // { def: "orderStatus", label: "orderStatus", visible: true },
    { def: "actions", label: "actions", visible: false }
  ];

  privatePortOrders!: EquityOrderDetails[];
  privatePortdisplayColumns: string[] = ['tradingsymbol', 'createdDate', 'transactionType', 'triggerPrice',
    'lastPrice', 'exitPrice', 'exitDate',
    'returnPercent', 'orderStatus', 'actions'];
  privatePortColumnDefinitions = [   
    { def: "tradingsymbol", label: "tradingsymbol", visible: true },
    { def: "createdDate", label: "CreatedDate", visible: true },
    { def: "transactionType", label: "transactionType", visible: true },
    { def: "triggerPrice", label: "triggerPrice", visible: true },
    { def: "lastPrice", label: "lastPrice", visible: true },
    { def: "exitPrice", label: "exitPrice", visible: true },
    { def: "exitDate", label: "exitDate", visible: true },
    { def: "returnPercent", label: "returnPercent", visible: true },
    // { def: "orderStatus", label: "orderStatus", visible: true },
    { def: "actions", label: "actions", visible: true }
  ];
  privatePortfolioSource = new MatTableDataSource<EquityOrderDetails>([]);
  @ViewChild('privateOrderpaginator') privateOrderpaginator!: MatPaginator;
  @ViewChild('privateOrderSort') privateOrderSort = new MatSort();

  constructor(private equityService: EquityService,
    fb: FormBuilder, private router: Router, private userService: UserService,
    private dialog: MatDialog, private dialogService: DialogService,private changeDetectorRefs: ChangeDetectorRef,
    @Inject(Injector) private readonly injector: Injector,
    private route: ActivatedRoute) {
    if (sessionStorage.getItem("userId") == null) {
      // this.router.navigateByUrl("login");
      sessionStorage.setItem("redirectUrl",router.url);      
    }else{
      sessionStorage.removeItem("redirectUrl");
    }
    const cd = this.columnDefinitions.find(column => column.def === "actions");
    if (cd && this.hasEquityMasterRole('equityMaster')) {
      cd.visible = true;
    }
  }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.selectedTab =  params['selectedTab'];       
      }
    )   
    this.getPubicPortfolios();
   
  }

  ngAfterViewInit() {
    console.log('Paginator:', this.subscribedPortfoliosPaginator);
    console.log('Sort:', this.subscribedPortfoliossort);
  }

  proPortNameSel!: string;
  privatePortSel!: string;
  searchField!: string;

  portfolioTypeConst:Option[] =  [
    { "name": "Private","value": "private", "checked": true,"disabled":false },
    { "name": "Public", "value": "public",  "checked": false,"disabled":false},
    { "name": "Pro", "value": "pro",  "checked": false,"disabled":false}
  ];

    proTypeConst:Option[] =  [
      { "name": "Yes","value": "yes", "checked": true, "disabled":false },
      { "name": "No", "value": "no", "checked": false,"disabled":false}];

   public buildShareLink(portfolioId:any, portfolioName:any): any {
    return environment.mfeApiUrl + "/portfolioview?portfolioId="+portfolioId+"&portfolioName=" + portfolioName;
  }

  getPrivatePortfolioNames() {
    console.log("this.userService.userId:"+sessionStorage.getItem('userId')+",this.userService.userId"+this.userService.userEmail);
    this.equityService.getPrivatePortfolioNames(sessionStorage.getItem('userId')).subscribe((res: any) => {
      this.agentPortifolios = res;      
      if(this.agentPortifolios?.length){
        console.log("getPrivatePortfolioNames::"+this.agentPortifolios[0]);
        if(sessionStorage.getItem('privatePortSessionSel') == null){
          this.privatePortSel  = this.agentPortifolios && this.agentPortifolios.length ? this.agentPortifolios[0].portfolioName : '';
          sessionStorage.setItem('privatePortSessionSel', this.privatePortSel );
        }else{
          const sessionValue = sessionStorage.getItem('privatePortSessionSel');
          if (sessionValue !== null) {
            this.privatePortSel = sessionValue;
          }          
        }    
        // this.getPrivatePortItems(this.privatePortSel);
    }
    });
  }

  getPubicPortfolios() {
    this.equityService.getAllPorfolios(sessionStorage.getItem('userId')).subscribe((res: any) => {
     
      this.freeSubscriptionPortifolios = res.freeSubscriptionList;    
      this.freeSubscriptionPortifoliosSource.data = this.freeSubscriptionPortifolios;
      this.freeSubscriptionPortifoliosSource.sort = this.freeSubPortfoliosort;
      this.freeSubscriptionPortifoliosSource.paginator = this.freeSubPortfolioPaginator;      

      this.subscribedPortfolios = res.premiumPortList;    
      this.subscribedPortfoliosSource.data = this.subscribedPortfolios;
      this.subscribedPortfoliosSource.sort = this.subscribedPortfoliossort;
      this.subscribedPortfoliosSource.paginator = this.subscribedPortfoliosPaginator;

      this.userProductList = res.productList;
      this.userPortfolioSubscriptionList = res.portfolioSubscriptionEntities;
      this.loggedUserId = sessionStorage.getItem('userId');

      // this.getPrivatePortfolioNames();    

    }); 
  }

  refreshPrivatePortfolios(){
    // this.getPrivatePortfolioNames();  
    this.changeDetectorRefs.detectChanges();
  }

  applyPrivateSearchFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.privatePortfolioSource.filter = filterValue.trim().toLowerCase();
    if (this.privatePortfolioSource.paginator) {
      this.privatePortfolioSource.paginator.firstPage();
    }
  }

  applySubscrebedPortfolioFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.subscribedPortfoliosSource.filter = filterValue.trim().toLowerCase();
    if (this.subscribedPortfoliosSource.paginator) {
      this.subscribedPortfoliosSource.paginator.firstPage();
    }
  }

  applyFreeSubscriptionPortfolioFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.freeSubscriptionPortifoliosSource.filter = filterValue.trim().toLowerCase();
    if (this.freeSubscriptionPortifoliosSource.paginator) {
      this.freeSubscriptionPortifoliosSource.paginator.firstPage();
    }
  }


  getPrivatePortItems(portfolioSelName: any) {

    console.log("getPrivatePortItems:selected private portfolio name:"+portfolioSelName);
    let portfolioDataObj =  this.agentPortifolios.find((data: any) => data.portfolioName === portfolioSelName);
    //  console.log("getPrivatePortItems for portfolio::"  + portfolioDataObj.portfolioName + "agentId:" + portfolioDataObj.agentId + "Id:" + portfolioDataObj.id);
     if(portfolioDataObj !== null && portfolioDataObj !== undefined){
     this.portfolioLinkToShare= this.buildShareLink(portfolioDataObj.id,portfolioDataObj.portfolioName);
     sessionStorage.setItem('privatePortSessionSel', portfolioSelName);
     this.equityService.getEquityOrderDetails(portfolioDataObj.agentId,portfolioDataObj.id).subscribe((res: any) => {
      this.privatePortOrders = res;
      this.privatePortfolioSource.data = this.privatePortOrders;
      this.privatePortfolioSource.sort = this.privateOrderSort;
      this.privatePortfolioSource.paginator = this.privateOrderpaginator;
    });
   }
  }


  openExitRecoDialog(element:any,
    operationType: string): void {

    let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === this.privatePortSel); 
    console.log("selected portfolio Name:" + portfolio.portfolioName + ",portfolio.id" +portfolio.id);
    if (element.transactionType == 'BUY') {
      element.transactionType = "SELL";
    } else if (element.transactionType == 'SELL') {
      element.transactionType = "BUY";
    }

    this.dialog.open(EquitydailogComponent, {
      data: {
        title: element.transactionType + ' ' + element.tradingsymbol,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',
        portfolioId:portfolio.id,
        portfolioName: portfolio.portfolioName,
        entryPrice:element.triggerPrice,
        exitPrice: element.strikeData.last_price,
        returnPercent:element.returnPercent,
        createdDate:element.createdDate,
        operationType: operationType,
        orderId: element._id,
        transactionType: element.transactionType,
        tradingsymbol: element.tradingsymbol,
        orderStatus: element.orderStatus
      },
    }).afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions.filter(cd => cd.visible).map(cd => cd.def);
  }

  getPrivatePortDisplayedColumns(): string[] {
    return this.  privatePortColumnDefinitions.filter(cd => cd.visible).map(cd => cd.def);
  }

  getFreeSubPortDisplayedColumns(): string[] {
    return this.  freeSubColumnDefinitions.filter(cd => cd.visible).map(cd => cd.def);
  }

  hasEquityMasterRole(role: string) {
    return this.userService.roles != undefined && this.userService.roles.includes(role);
  }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  openAddStockDialog(portfolioName:any): void {

    if(portfolioName == "" || portfolioName == undefined || portfolioName == null){
      this.toast.info('please select the portfolio');
    }else{
    let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === portfolioName);    
    console.log("selected portfolio Name:" + portfolio.portfolioName + ",portfolio.id" +portfolio.id);
    const dialogRef = this.dialog.open(AddStockDialogComponent, {
      data: {
        title: 'Add stock to' + ' ' + portfolio.portfolioName,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',
        portfolioId:portfolio.id,
        portfolioName: portfolio.portfolioName       
      },
    }).afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  }

  addPortfolioNameDialogOpen(action:any, customTitle:any) {
   
    const dialogRef = this.dialog.open(AddPortfolioDialogComponent, {
      data: {
        title: customTitle,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',       
        action:action,
        portfolioType: this.portfolioTypeConst,
        portfolioPro:  this.proTypeConst
      },
    }).afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  renamePortfolioDialogOpen(portfolioName:any, action:any, customTitle:any) {
    
    if(portfolioName == "" || portfolioName == undefined || portfolioName == null){
      this.toast.info('please select the portfolio');
    }else{
      let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === portfolioName);   
    
      const dialogRef = this.dialog.open(AddPortfolioDialogComponent, {
        data: {
          title: customTitle,
          message: 'This is a sample dialog opened using Angular Material MatDialog!',
          portfolio: portfolio,
          action:action,
          portfolioType: this.portfolioTypeConst,
          // portfolioTypeDef:
          portfolioPro:  this.proTypeConst
        },
      }).afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    }
  }

  portfolioSubscriptionDialogOpen(portfolioName:any, action:any, customTitle:any) {
    
    if(portfolioName == "" || portfolioName == undefined || portfolioName == null){
      this.toast.info('please select the portfolio');
    }else{
      let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === portfolioName);     
      const dialogRef = this.dialog.open(ViewPortSubscriberComponent, {
        data: {
          title: customTitle,
          message: 'This is a sample dialog opened using Angular Material MatDialog!',
          portfolio: portfolio          
        },
      }).afterClosed().subscribe(result => {
        // this.ngOnInit();
      });
    }
  }

  deletePortfolio(portfolioName:any) {
    if(portfolioName == "" || portfolioName == undefined || portfolioName == null){
      this.toast.info('please select the portfolio');
    }else{
    let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === portfolioName);
    this.dialogService.openConfirmDailog("deletion", "Are you sure you want to delete " + portfolio.portfolioName + " portfolio and content of it ?")
    .afterClosed()
    .subscribe(res => {
      if (res) {
        let deletePortObj:Portfolio = new Portfolio;
        deletePortObj.agentId = Number(sessionStorage.getItem('userId'));
        deletePortObj.id = portfolio.id;
        deletePortObj.portfolioName = portfolio.portfolioName;
        deletePortObj.portfolioValue = portfolio.portfolioName;
        this.equityService.deletePortfolio(deletePortObj).subscribe(data => {
          this.toast.success('Portfolio deleted Successfully!');
          sessionStorage.removeItem('privatePortSessionSel');
          this.ngOnInit();
        })
      }
    })
  }
  }

  removeEquityOrder(orderId: any, itemName: any, dialogTitle: any) {
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to delete " + itemName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.equityService.removeEquityOrder(Number(sessionStorage.getItem('userId')), orderId).subscribe(data => {
            this.ngOnInit();
          })
        }
      })
  }

  subscriberPortfolio(portfolio:any,dialogTitle: any) {
    
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to Subscribe " + portfolio.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          portfolio.loginId = Number(sessionStorage.getItem('userId'));
          this.equityService.subscribePortfolio(portfolio).subscribe(data => {
            this.ngOnInit();
          })
        }
      })
  }

  raisePortfolioRequest(portfolio:any,dialogTitle: any) {
    
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to raise request " + portfolio.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          portfolio.loginId = Number(sessionStorage.getItem('userId'));
          this.equityService.raisePortfolioRequest(portfolio).subscribe(data => {
            this.ngOnInit();
          })
        }
      })
  }

  unSubscribePortfolio(portfolio:any,dialogTitle: any) {
    // let portfolio =  this.proPortifolios.find((data: any) => data.portfolioName === portfolioSelName);
    if(portfolio != undefined){
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to UnSubscribe " + portfolio?.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          if(portfolio != undefined){
            portfolio.loginId = Number(sessionStorage.getItem('userId'));
            portfolio.statusUpdate = "InActive";
          }          
          this.equityService.updatePortSubscriptionStatus(portfolio).subscribe(data => {
            // console.log("successfull unSubscribePortfolio");
            sessionStorage.removeItem('proPortSessionSel');
            this.ngOnInit();
          })
        }
      })
    }
  }

  renewSubscribePortfolio(portfolio:any,dialogTitle: any) {
    
    if(portfolio != undefined){
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to renew " + portfolio?.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          if(portfolio != undefined){
            portfolio.loginId = Number(sessionStorage.getItem('userId'));
            portfolio.statusUpdate = "Active";
          }          
          this.equityService.updatePortSubscriptionStatus(portfolio).subscribe(data => {
            this.ngOnInit();
          })
        }
      })
    }
  }


  checkIfProEnabled(portfolioObj:any){  
    const matchedPortfolios = this.userPortfolioSubscriptionList.filter(product =>
      product.portfolioId === portfolioObj.id
    );
    if (matchedPortfolios && matchedPortfolios.length > 0 && portfolioObj != undefined 
      && portfolioObj.portfolioType === 'pro' && matchedPortfolios[0].status === 'Pending'){
      return false;
    }else if(portfolioObj != undefined && portfolioObj.portfolioType === 'pro'){   
    return true;
    }else{
      return false;
    }
  }


  checkIfSubscribeEnabled(portfolioObj:any){  
    //console.log(portfolioObj.id+"++++++++++++++++portfolioObj.portfolioType>>>>"+portfolioObj.portfolioType+"-----portfolioObj.portfolioName:::"+portfolioObj.portfolioName);
    if (this.userPortfolioSubscriptionList != null || this.userPortfolioSubscriptionList != undefined) {
      const subscribed = Object.values(this.userPortfolioSubscriptionList).filter(product =>
        product.portfolioId === portfolioObj.id).length > 0;
      const matchedPortfolios = this.userPortfolioSubscriptionList.filter(product =>
        product.portfolioId === portfolioObj.id
      );
      if ((matchedPortfolios && matchedPortfolios.length > 0 && (matchedPortfolios[0].status === 'Active' || matchedPortfolios[0].status === 'InActive')
        || portfolioObj.portfolioType === 'pro')) {
        return true;
      }
    }
    return false;
  }

  checkIfRenewalEnabled(portfolioObj:any){  
    if(this.userPortfolioSubscriptionList != null || this.userPortfolioSubscriptionList != undefined){
    const subscribedEarlier = Object.values(this.userPortfolioSubscriptionList).filter(product => 
      product.portfolioId === portfolioObj.id && product.status === 'InActive').length > 0;
    if(subscribedEarlier)
    {
      return true;
    }
  }
    return false;
  }

}
