import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Portfolio } from 'src/app/model/portfolio';
import { EquityService } from 'src/app/services/equity.service';
import { UserService } from 'src/app/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { PortfolioSubscription } from 'src/app/model/PortfolioSubscription';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-view-port-subscriber',
  templateUrl: './view-port-subscriber.component.html',
  styleUrls: ['./view-port-subscriber.component.css']
})
export class ViewPortSubscriberComponent {

  portfolioTypeSel!:any;
  portfolioProSel!:any;
  triggerPrice!:any;
  trigerPriceDisable!:boolean;

  subscriberPendingSource = new MatTableDataSource<PortfolioSubscription>([]);
  @ViewChild('subscriberListPaginator') subscriberListPaginator!: MatPaginator;
  @ViewChild('subscriberListSort') subscriberListSort = new MatSort();

  columnDefinitions = [
    { def: "createdDate", label: "CreatedDate", visible: true },
    { def: "subscriberId", label: "SubscriberId", visible: true },
    { def: "subscriberName", label: "SubscriberName", visible: true },
    { def: "status", label: "Status", visible: true },
    { def: "actions", label: "Actions", visible:true}
  ];


  constructor(private router:Router, private dialog:MatDialog,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(MAT_DIALOG_DATA)  public data: { 
    title: string, message: string,    
    portfolio:Portfolio    
  }, 
  private equityService:EquityService,private userService:UserService){ 
    this.getPortSubscriberDetails();
   }

  getPortSubscriberDetails() {
    this.equityService.getPortSubscriberDetails(this.data.portfolio.id,sessionStorage.getItem('userId')).subscribe((res: any) => {
      this.subscriberPendingSource.data = res;
      this.subscriberPendingSource.sort = this.subscriberListSort;
      this.subscriberPendingSource.paginator = this.subscriberListPaginator; 
    });
  }

  submitPortfolioApprovals(element:any, operationType: string) {
    console.log("subscriberId::"+element.subscriberId+",operationType:"+operationType);
    // element.loginId = Number(sessionStorage.getItem('userId'));
    element.statusUpdate = operationType;
    this.equityService.updateProPortfolioSubscriptionStatus(element).subscribe(data => {
      // sessionStorage.removeItem('proPortSessionSel');
      this.getPortSubscriberDetails();
    })  
  }

   getDisplayedColumns(): string[] {
    return this.columnDefinitions.filter(cd => cd.visible).map(cd => cd.def);
  }

}
