<style>
  table {
    border-collapse: separate;
    border-spacing: 10px 0;
  }
  th {
    background-color: #4287f5;
    color: white;
  }
  th,
  td {
    width: 150px;
    text-align: center;
    border: 1px solid black;
    padding: 5px;
  }
  h2 {
    color: #4287f5;
  }
</style>
<mat-form-field appearance="outline" class="form-element">
    <mat-label>Select ExpiryDate</mat-label>
    <mat-select [(ngModel)]="expiryDateSel" name="expiryDateSel" class="form-element"
      (selectionChange)="loadWeekDates()">
      <mat-option *ngFor="let expiryDate of expiryDateList" [value]="expiryDate">
        {{expiryDate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-element">
    <mat-label>Select Date</mat-label>
    <mat-select [(ngModel)]="dateSel" name="dateSel" class="form-element"
      (selectionChange)="loadChainData()">
      <mat-option *ngFor="let weekDate of weekDateList" [value]="weekDate">
        {{weekDate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button matTooltip="Refresh" color="primary" mat-button (click)="loadChainData()">
    <mat-icon>refresh</mat-icon>
  </button>

  <div class="row">
    <div class="col-md-6 mb-4">
    <table>
      <thead>
        <tr>
          <th>StrikeName</th>
          <th>400 Level</th>
          <th>70 Level</th>
        </tr>
      </thead>
      <tbody>
        <tr style="border-bottom: 1px solid black;" *ngFor="let strikeData of optionStrikeData?.hickedUpCEStrikeList">          
          <td>{{ strikeData.strikeBS400ChainName    ? strikeData.strikeBS400ChainName : '-' }}</td>
          <td>{{strikeData.yestBM400HigherRangeTime ? strikeData.yestBM400HigherRangeTime : '-' }}</td>
          <td>{{strikeData.bMBelowOf70RangeTime  ? strikeData.bMBelowOf70RangeTime : '-' }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="col-md-6 mb-4">
    <table>
      <thead>
        <tr>
          <th>StrikeName</th>
          <th>400 Level</th>
          <th>70 Level</th>
        </tr>
      </thead>
      <tbody>
        <tr style="border-bottom: 1px solid black;" *ngFor="let strikeData of optionStrikeData?.hickepPEStrikeList">
          <td>{{ strikeData.strikeBS400ChainName    ? strikeData.strikeBS400ChainName : '-' }}</td>
          <td>{{strikeData.yestBM400HigherRangeTime ? strikeData.yestBM400HigherRangeTime : '-' }}</td>
          <td>{{strikeData.bMBelowOf70RangeTime  ? strikeData.bMBelowOf70RangeTime : '-' }}</td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>

  <div class="row">
  <div class="col-md-6 mb-4">
  <table *ngFor="let dayStrikeData of optionStrikeData?.dayWiseCEStrikeList">
    <thead>
      <tr>
        <th>StrikeName</th>
        <th>400 Level</th>
        <th>350 Level</th>
        <th>300 Level</th>
        <th>250 Level</th>
      </tr>
    </thead>
    <tbody>
      <tr style="border-bottom: 1px solid black;" *ngFor="let strikeData of dayStrikeData">
        
        <td>{{ strikeData.strikeBS400ChainName    ? strikeData.strikeBS400ChainName : '-' }}</td>
        <td>{{strikeData.yestBM400HigherRangeTime ? strikeData.yestBM400HigherRangeTime : '-' }}</td>
        <td>{{strikeData.bM350After400RangeTime ? strikeData.bM350After400RangeTime : '-' }}</td>
        <td>{{strikeData.bM300After400RangeTime ? strikeData.bM300After400RangeTime : '-' }}</td>
        <td>{{strikeData.bM250After400RangeTime  ? strikeData.bM250After400RangeTime : '-' }}</td>
        <!-- <td>{{ strikeData.strikePriceInBM400HR }} - {{strikeData.yestBM400HigherRangeTime}}</td>
        <td>{{ strikeData.strikePriceInBM300HR }} - {{strikeData.yestBM300HigherRangeTime}}</td>
        <td>{{ strikeData.strikePriceInBM350HR }} - {{strikeData.yestBM350HigherRangeTime}}</td>
        <td>{{ strikeData.strikePriceInBM350LR }} - {{strikeData.yestBM350LowerRangeTime}}</td> -->
      </tr>
    </tbody>
  </table>
  </div>
  <div class="col-md-6 mb-4">
  <table *ngFor="let dayStrikeData of optionStrikeData?.dayWisePEStrikeList">
    <thead>
      <tr>
        <th>StrikeName</th>
        <th>400 Level</th>
        <th>350 Level</th>
        <th>300 Level</th>
        <th>250 Level</th>
      </tr>
    </thead>
    <tbody>
      <tr style="border-bottom: 1px solid black;" *ngFor="let strikeData of dayStrikeData">
        <td>{{ strikeData.strikeBS400ChainName    ? strikeData.strikeBS400ChainName : '-' }}</td>
        <td>{{strikeData.yestBM400HigherRangeTime ? strikeData.yestBM400HigherRangeTime : '-' }}</td>
        <td>{{strikeData.bM350After400RangeTime ? strikeData.bM350After400RangeTime : '-' }}</td>
        <td>{{strikeData.bM300After400RangeTime ? strikeData.bM300After400RangeTime : '-' }}</td>
        <td>{{strikeData.bM250After400RangeTime  ? strikeData.bM250After400RangeTime : '-' }}</td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>

  <div class="chart-container">
    <canvas  id="MyCEChart" >{{ ceChart }}</canvas>
</div>

<div class="chart-container">
  <canvas  id="MyPEChart" >{{ peChart }}</canvas>
</div>
