<div class="center">
<div *ngIf="resultSuccess">
    <p class="mat-body">{{this.resultSuccess}}</p>
    <div class="resetPassword">
        <button mat-raised-button color="primary" routerLink="/login">Click here to login</button>
    </div>
</div>
<div *ngIf="resultFailed">
    <p>{{this.resultFailed}}</p>
</div>
</div>

