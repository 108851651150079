<mat-form-field appearance="outline" class="form-element">
    <mat-label>Select ExpiryDate</mat-label>
    <mat-select [(ngModel)]="expiryDateSel" name="expiryDateSel" class="form-element"
      (selectionChange)="loadWeekDates(expiryDateSel)">
      <mat-option *ngFor="let expiryDate of expiryDateList" [value]="expiryDate">
        {{expiryDate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-element">
    <mat-label>Select Date</mat-label>
    <mat-select [(ngModel)]="dateSel" name="dateSel" class="form-element"
      (selectionChange)="loadStrikeDataByExpiry()">
      <mat-option *ngFor="let weekDate of weekDateList" [value]="weekDate">
        {{weekDate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <table border="1">
    <thead>
      <tr>
        <th>Name</th>
        <th *ngFor="let strike of optionStrikeData[0]?.strikes">Price</th>
      </tr>
    </thead>
    <tbody>
      <tr style="border-bottom: 1px solid black;" *ngFor="let strike of optionStrikeData[0]?.strikes">
        <td>{{ strike.name }}</td>
        <td *ngFor="let price of strike.pricelist">{{ price }}</td>
      </tr>
    </tbody>
  </table>
  
