<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Login & Signup</title>
</head>
<body>
    <div class="container">
        <input type="checkbox" id="toggle" aria-hidden="true">
        
        <!-- Signup Form -->
        <div class="form-container signup-form">
            <form [formGroup]="signupForm" (submit)="signup()">
                <label for="toggle" aria-hidden="true">Register</label>
                <input type="text" name="name" formControlName="name" placeholder="User name">
                <input type="text" name="phone" formControlName="phone" placeholder="Phone" minlength="10" maxlength="10">
                <input type="email" name="email" formControlName="email" placeholder="Email">
                <input type="password" name="password" formControlName="password" placeholder="Password">
                <button type="submit">Sign up</button>
                <p class="switch-form">Already have an account? <label for="toggle">Login</label></p>
            </form>
        </div>

        <!-- Login Form -->
        <div class="form-container login-form">
            <form [formGroup]="loginForm" (submit)="signin()">
                <label for="toggle" aria-hidden="true">Login</label>
                <input type="email" name="email" formControlName="email" placeholder="Email">
                <input type="password" name="password" formControlName="password" placeholder="Password">
                <button type="submit">Login</button>
                <p class="switch-form">Don't have an account? <label for="toggle">Register</label></p>
                <div class="resetPassword">
                    <a mat-raised-button href="#" [routerLink]="" (click)="resetPasswordRequest('hello')">Forgot password?</a>
                </div>
            </form>
        </div>
    </div>
</body>
</html>
