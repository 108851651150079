import { Component, Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from 'src/app/model/userprofile';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { PasswordResetRequestComponent } from './password-reset-request/password-reset-request.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginForm!: FormGroup;
  signupForm!:FormGroup;
  user: UserProfile = new UserProfile();

  constructor(private router: Router, private loginService: LoginService,
    private dialog: MatDialog,@Inject(Injector) private readonly injector: Injector, private userService: UserService,
  ) { }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.signupForm = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required,Validators.pattern("^[0-9]+(\\.[0-9]+)*$"),Validators.minLength(10),Validators.maxLength(10)])
      }
    )
    this.loginForm = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required])       
      }
    )
  }

  signin() {
    this.user = this.loginForm.getRawValue();
    if( this.loginForm.valid){
    this.loginService.signin(this.user).subscribe((res: any) => {
      this.userService.isLoggedIn = true;
      this.userService.userEmail = res.email;
      this.userService.loggedInUser = res.loggedInUser;
      this.userService.userName = res.name;
      this.userService.userId = res.id;
      this.userService.roles = res.roles;

      AuthInterceptor.accessToken = res.accessToken;
      AuthInterceptor.refreshToken = res.refreshToken;

      sessionStorage.setItem('refreshToken', res.refreshToken);
      sessionStorage.setItem('loggedInUser', res.email);

      if(sessionStorage.getItem('redirectUrl') != null){
        let redUrl = sessionStorage.getItem('redirectUrl');
        console.log("redUrl::",redUrl);
        this.router.navigateByUrl(redUrl != null? redUrl:'trade');
      }else{
        this.router.navigateByUrl("dashboard");
      }
      
    }, (error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.toast.error('Invalid email and password', error.error);
      } else {
        this.toast.error('Error:', error.error);
      }
    }
  );
  }else{
      this.toast.info('Please enter valid details');
    }
}

  signup() {
    this.user = this.signupForm.getRawValue();
    if(this.signupForm.valid){      
    this.loginService.signup(this.user).subscribe((res: any) => {
      this.toast.success('An activation email has been sent. Please check your inbox to activate your account and log in.');
    },
      (error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.toast.error('User email is already Exists', error.error);
        } else {
          this.toast.error('Error:', error.error);
        }
      }
    );
    }else{
      this.toast.info('please enter valid details to signup');
    }
  }

  resetPasswordRequest(customTitle:any) {   
    const dialogRef = this.dialog.open(PasswordResetRequestComponent, {
      data: {
        title: customTitle,
        message: 'This is a sample dialog opened using Angular Material MatDialog!'       
      },
    }).afterClosed().subscribe(result => {
      // this.toast.info('please enter valid details to signup');
    });
  }

  updateUserProfile() {
    this.user = this.signupForm.getRawValue();
    this.loginService.updateUserProfile(this.user).subscribe((res: any) => {
      // this.router.navigateByUrl("subscribers");
    });
  }
}
