import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrderDetails } from 'src/app/model/orderdetails';
import { TradeService } from 'src/app/services/trade.service';
import { UserService } from 'src/app/services/user.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent {

  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
 
  orderDetails!: OrderDetails[];
  displayedColumns:string[] = ['createdDate','orderId','tradingsymbol','triggerQnty',
  'transactionType','productType','orderVariety','orderStatus','profitLoss'];
  dataSource = new MatTableDataSource<OrderDetails>([]);
  @ViewChild('activeOrderpaginator') activeOrderpaginator!:MatPaginator;
  @ViewChild('activeOrderSort') activeOrderSort = new MatSort();
  totalAmt!:number;


  constructor(private tradeService: TradeService,
    private router:Router,private userService:UserService){
  }

  ngOnInit(){
    
  }

  onSearchSubmit(){
    console.log('startDate:'+this.campaignOne.value.start);
    console.log('endDate:'+this.campaignOne.value.end);
    this.getReportOrders(this.campaignOne.value.start,this.campaignOne.value.end); 
     
  }
  applyFilter(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

   getReportOrders(startDate:any,endDate:any){
    //console.log("getting getLimitOpenOrders details on load:::");
   this.tradeService.getReportOrders(sessionStorage.getItem('userId'),startDate,endDate).subscribe((res: any) => {
    this.orderDetails = res;
    this.totalAmt =  this.orderDetails.map(t => t.totalProfitLoss).reduce((acc, value) => acc + value, 0);
    this.dataSource.data = this.orderDetails;
    this.dataSource.sort = this.activeOrderSort;
    this.dataSource.paginator = this.activeOrderpaginator;
    });
  }
}
