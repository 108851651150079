import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormGroup, ReactiveFormsModule} from '@angular/forms'
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { Route, RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { LoginService } from './services/login.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SubscriberComponent } from './components/subscriber/subscriber.component';
import { ReportsComponent } from './components/reports/reports.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { EquityComponent } from './components/equity/equity.component';
import { AddsubscriberComponent } from './components/subscriber/addsubscriber/addsubscriber.component';

import { UserProfile } from './model/userprofile';
import { Subscriber } from './model/subscriber';
// import { MaterialModule } from './material-module';
import {MatTableDataSource} from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule} from '@angular/material/paginator';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ClipboardModule} from '@angular/cdk/clipboard';


import { UserService } from './services/user.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TradeComponent } from './components/trade/trade.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TradedialogComponent } from './components/trade/tradedialog/tradedialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { EquitydailogComponent } from './components/equity/equitydailog/equitydailog.component';
import { ProductsComponent } from './components/products/products.component';
import { AddStockDialogComponent } from './components/equity/add-stock-dialog/add-stock-dialog.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AddPortfolioDialogComponent } from './components/equity/add-portfolio-dialog/add-portfolio-dialog.component';
import { PortfolioViewComponent } from './components/equity/portfolio-view/portfolio-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CanactivateGuardComponent } from './guards/canactivate-guard/canactivate-guard.component';
import { KiteConnectService } from './services/kite-connect.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PasswordResetComponent } from './components/login/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './components/login/password-reset-request/password-reset-request.component';
import { VerifyUserComponent } from './components/login/verify-user/verify-user.component';
import { AddWatchlistDialogComponent } from './components/trade/add-watchlist-dialog/add-watchlist-dialog.component';
import { DataDisplayComponent } from './components/options/data-display/data-display.component';
import { ChainDataComponent } from './components/options/chain-data/chain-data.component';
import { ViewPortSubscriberComponent } from './components/equity/view-port-subscriber/view-port-subscriber.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SubscriberComponent,
    ReportsComponent,
    AboutusComponent,
    EquityComponent,
    TradeComponent,
    ProfileComponent,
    TradedialogComponent,
    AddsubscriberComponent,
    ConfirmationDialogComponent,
    EquitydailogComponent,
    ProductsComponent,
    AddStockDialogComponent,
    AddPortfolioDialogComponent,
    PortfolioViewComponent,
    DashboardComponent,
    ContactusComponent,
    PasswordResetComponent,
    PasswordResetRequestComponent,
    VerifyUserComponent,
    AddWatchlistDialogComponent,
    DataDisplayComponent,
    ChainDataComponent,
    ViewPortSubscriberComponent
   
  ],
  imports: [
    BrowserModule,BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,ClipboardModule,
    ToastrModule.forRoot({
      timeOut: 4500,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
}),
    ReactiveFormsModule,
    MatTableModule,MatSortModule,MatToolbarModule,MatDialogModule,MatInputModule,MatFormFieldModule,
    MatIconModule,MatMenuModule,MatButtonModule,MatSidenavModule,
    MatPaginatorModule,MatListModule,MatSelectModule,MatRadioModule,MatTabsModule,MatSlideToggleModule,MatExpansionModule,
    MatNativeDateModule,MatDatepickerModule,MatTooltipModule
  ],
  providers: [LoginService,UserService,UserProfile,Subscriber,TradeComponent, KiteConnectService, CanactivateGuardComponent,
    {provide:HTTP_INTERCEPTORS, useClass:AuthInterceptor,multi:true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
