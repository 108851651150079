export class Favorites{
    agentId=0;
    name!:string;
    tradingsymbol!:string;  
    instrument_token!:number;
	exchange_token!:number;
	last_price!:number;
	tick_size!:number;
	instrument_type!:string;
	segment!:string;
	exchange!:string;
	strike!:string;
	lot_size!:number;
	expiry!:string;
	portfolioId!:number;
}