import { Component, Inject, Injector, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscriber } from 'src/app/model/subscriber';
import { AddsubscriberComponent } from './addsubscriber/addsubscriber.component';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})
export class SubscriberComponent {

  constructor(private router: Router,public dialog: MatDialog,
    @Inject(Injector) private readonly injector: Injector,
    private subscriberService: SubscriberService, private dialogService: DialogService) {
   }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  inputSubscriber: Subscriber = new Subscriber();
  subscriber!: Subscriber[];
  displayedColumns: string[] = ['name', 'email', 'tradeTenant','apisecret', 'apikey', 'requestToken', 'active', 'actions'];


  dataSource = new MatTableDataSource<Subscriber>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
    this.loadSubscribers();
  }

  loadSubscribers() {
    this.subscriberService.getSubscriberList(sessionStorage.getItem('userId')).subscribe((res: Subscriber[]) => {
      this.subscriber = res;
      this.dataSource.data = [...this.subscriber];
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openAddSubsriberDialog() {
    this.dialog.open(AddsubscriberComponent, {
      disableClose:true,
      data: { subscriber: this.subscriber}    
    }).afterClosed().subscribe(result => {
      this.loadSubscribers();
    })

  }

  setRefreshToken(element: any) {
    this.inputSubscriber.apikey = element.apikey;
    this.inputSubscriber.agentId = element.agentId;
    this.inputSubscriber._id = element._id;
    this.inputSubscriber.requestToken = element.requestToken;
    this.inputSubscriber.userName = element.userName;
    this.subscriberService.generateAccessToken(this.inputSubscriber).subscribe((data:any) => {
      this.toast.info(data);
      this.loadSubscribers(); 
    }, (error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.toast.error('Api keyToken is wrong', error.error);
      } else if (error.status === 400) {
        this.toast.error(error.error);
      }
    });
  }

  edit(e: any) {
    e.editable = !e.editable;
  }

  deleteSubscriber(element: any, userName: any, DialogTitle: any) {
    this.dialogService.openConfirmDailog(DialogTitle, "Are you sure you want to delete " + userName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.subscriberService.deleteSubscriber(element).subscribe(data => {
            this.loadSubscribers();
          })
        }
      })
  }
}
