import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent {

constructor(private router: Router,private userService: UserService,){
  console.log('aboutus Component:'+this.userService.isLoggedIn);
}

}
