import { ChangeDetectorRef, Component, Inject, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EquityOrderDetails } from 'src/app/model/equityOrderDetails';
import { Portfolio } from 'src/app/model/portfolio';
import { DialogService } from 'src/app/services/dialog.service';
import { EquityService } from 'src/app/services/equity.service';
import { UserService } from 'src/app/services/user.service';
import {Chart} from 'chart.js/auto';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AddPortfolioDialogComponent } from '../add-portfolio-dialog/add-portfolio-dialog.component';
import { AddStockDialogComponent } from '../add-stock-dialog/add-stock-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ViewPortSubscriberComponent } from '../view-port-subscriber/view-port-subscriber.component';
import { EquitydailogComponent } from '../equitydailog/equitydailog.component';

import { Option } from 'src/app/model/Option';


@Component({
  selector: 'app-navigate-portfolio',
  templateUrl: './portfolio-view.component.html',
  styleUrls: ['./portfolio-view.component.css']
})
export class PortfolioViewComponent {

  portfolioName!: string;
  portfolioId!:number;
  chart:Chart | undefined;

  orderDetails!: EquityOrderDetails[];
  dataSource = new MatTableDataSource<EquityOrderDetails>([]);
  @ViewChild('portfolioViewPaginator') portfolioViewPaginator!: MatPaginator;
  @ViewChild('portfolioViewSort') portfolioViewSort = new MatSort();
  panelOpenState = false;
  displayedColumns: string[] = [ 'tradingsymbol', 'createdDate', 'transactionType', 'triggerPrice',
    'lastPrice', 'exitPrice', 'exitDate',
    'returnPercent', 'orderStatus', 'actions'];
  columnDefinitions = [    
    { def: "tradingsymbol", label: "tradingsymbol", visible: true },   
    { def: "triggerPrice", label: "triggerPrice", visible: true },
    { def: "lastPrice", label: "lastPrice", visible: true },
    { def: "exitPrice", label: "exitPrice", visible: true },
    { def: "returnPercent", label: "returnPercent", visible: true },
    { def: "transactionType", label: "transactionType", visible: true },
    { def: "createdDate", label: "CreatedDate", visible: true },
    { def: "exitDate", label: "exitDate", visible: true },
    { def: "orderStatus", label: "orderStatus", visible: true },
    { def: "actions", label: "actions", visible: false }
  ];

  portfolioTypeConst:Option[] =  [
    { "name": "Private","value": "private", "checked": true,"disabled":false },
    { "name": "Public", "value": "public",  "checked": false,"disabled":false},
    { "name": "Pro", "value": "pro",  "checked": false,"disabled":false}
  ];

  portfolioObj!:Portfolio;
  freeSubscriptionAllowed!:boolean;
  cancelSubscriptionAllowed!:boolean;
  paidSubscriptionAllowed!:any;
  renewSubscriptionAllowed!:any;
  requestApprovalPending!:any;
  portfolioOwnerSame!:boolean;
  

  periodSelItem:any = '6';

  monthlyData!:any;
  constructor(private route:ActivatedRoute,private dialog: MatDialog,
    private router:Router, private userService:UserService,private equityService: EquityService,
    private dialogService: DialogService,private changeDetectorRefs: ChangeDetectorRef,
    @Inject(Injector) private readonly injector: Injector
    ) {
     
      if (sessionStorage.getItem('userId') != null) {
        sessionStorage.removeItem("redirectUrl");
      }
   }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.portfolioId =  params['portfolioId'];
        this.portfolioName =  params['portfolioName'];       
      }
    )
    this.getPublicPortfolioItems();    
   
  }

  private get toast() {
    return this.injector.get(ToastrService);
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions.filter(cd => cd.visible).map(cd => cd.def);
  }

  public buildShareLink(portfolioId:any, portfolioName:any): any {
    return environment.mfeApiUrl + "/portfolioview?portfolioId="+portfolioId+"&portfolioName=" + portfolioName;
  }

  applyEquityOrderFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPublicPortfolioItems() {
    // console.log("getRestrictedPortfolioItems:portfolioName:"+this.portfolioName+",this.userService::"+this.userService.userId);
    this.equityService.getPublicPortfolioItems(this.portfolioId,sessionStorage.getItem('userId')).subscribe((res: any) => {
      this.orderDetails = res.equityOrderDetailsList;
      this.dataSource.data = this.orderDetails;
      this.dataSource.sort = this.portfolioViewSort;
      this.dataSource.paginator = this.portfolioViewPaginator;  
      this.portfolioObj = res.portfolioEntity;
      this.portfolioObj.loginId = Number(sessionStorage.getItem('userId'));

      this.freeSubscriptionAllowed = res.freeSubscriptionAllowed;
      this.paidSubscriptionAllowed = res.paidSubscriptionAllowed;
      this.renewSubscriptionAllowed = res.renewSubscriptionAllowed;
      this.requestApprovalPending = res.requestApprovalPending;
      this.cancelSubscriptionAllowed = res.cancelSubscriptionAllowed;
      this.portfolioOwnerSame = res.portfolioOwner;
      const cd = this.columnDefinitions.find(column => column.def === "actions");
      if (cd && this.portfolioOwnerSame) {
        cd.visible = true;
      }
      this.getPortfolioMonthlyData();

    });
  }

  // refreshPortfolioView(){
  //   this.getPublicPortfolioItems()
  // }
  
  ondurationSelect(){
    this.getPortfolioMonthlyData();
  }

  getPortfolioMonthlyData() {
    // console.log("getPortfolioMonthlyData:portfolioName:"+this.portfolioName+"period::"+this.periodSelItem);
    this.equityService.getPortfolioMonthlyData(this.portfolioId,this.periodSelItem).subscribe((res: any) => {
     this.monthlyData = res;
     this.chart?.destroy();
      this.chart = new Chart("MyChart", {
        type: 'bar',   
        data: {// values on X-Axis
          labels: this.monthlyData.monthList, 
          datasets: [
            {
              label: "Entries",
              data: this.monthlyData.createdCount,
              backgroundColor: 'blue'
            },
            {
              label: "Exit Profit",
              data: this.monthlyData.exitCount,
              backgroundColor: 'limegreen'
            },
            {
              label: "Exit loss",
              data: this.monthlyData.lossBookingCount,
              backgroundColor: 'red'
            }  
          ]
        },
        options: {
          aspectRatio:2.5
        }
        
      });
    });
  }
 
 
   subscriberPortfolio(dialogTitle: any) {
    
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to Subscribe " + this.portfolioObj.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {          
          this.equityService.subscribePortfolio(this.portfolioObj).subscribe(data => {
            this.ngOnInit();
          })        
        }
      })
  }

  unSubscribePortfolio(dialogTitle: any) {
    if(this.portfolioObj != undefined){
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to UnSubscribe " + this.portfolioObj?.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          if(this.portfolioObj != undefined){
            this.portfolioObj.statusUpdate = "InActive";
          }          
          this.equityService.updatePortSubscriptionStatus(this.portfolioObj).subscribe(data => {
            // sessionStorage.removeItem('proPortSessionSel');
            this.ngOnInit();
          })
        }
      })
    }
  }

  raisePortfolioRequest(dialogTitle: any) {
    
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to raise request " +  this.portfolioObj.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.equityService.raisePortfolioRequest(this.portfolioObj).subscribe(data => {
            this.ngOnInit();
          })
        }
      })
  }

  renewSubscribePortfolio(dialogTitle: any) {
    
    if(this.portfolioObj != undefined){
    this.dialogService.openConfirmDailog(dialogTitle, "Are you sure you want to renew " + this.portfolioObj?.portfolioName + " ?")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          if(this.portfolioObj != undefined){
            this.portfolioObj.statusUpdate = "Active";
          }          
          this.equityService.updatePortSubscriptionStatus(this.portfolioObj).subscribe(data => {
            this.ngOnInit();
          })
        }
      })
    }
  }

  openAddStockDialog(): void {
    let portfolio =  this.portfolioObj;  
    console.log("selected portfolio Name:" + portfolio.portfolioName + ",portfolio.id" +portfolio.id);
    const dialogRef = this.dialog.open(AddStockDialogComponent, {
      data: {
        title: 'Add stock to' + ' ' + portfolio.portfolioName,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',
        portfolioId:portfolio.id,
        portfolioName: portfolio.portfolioName       
      },
    }).afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }  
  
  portfolioSubscriptionDialogOpen(customTitle:any) {
      let portfolio =  this.portfolioObj;   
      const dialogRef = this.dialog.open(ViewPortSubscriberComponent, {
        data: {
          title: customTitle,
          message: 'This is a sample dialog opened using Angular Material MatDialog!',
          portfolio: portfolio          
        },
      }).afterClosed().subscribe(result => {
        // this.ngOnInit();
      });
  }

  renamePortfolioDialogOpen(action:any, customTitle:any) {
    
      let portfolio =  this.portfolioObj;  
    
      const dialogRef = this.dialog.open(AddPortfolioDialogComponent, {
        data: {
          title: customTitle,
          message: 'This is a sample dialog opened using Angular Material MatDialog!',
          portfolio: this.portfolioObj,
          action:action,
          portfolioType: this.portfolioTypeConst,
          // portfolioTypeDef:
          // portfolioPro:  this.portfolioTypeConst
        },
      }).afterClosed().subscribe(result => {
        this.ngOnInit();
      });
  }


  deletePortfolio() {
    // let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === portfolioName);
    this.dialogService.openConfirmDailog("deletion", "Are you sure you want to delete " + this.portfolioObj.portfolioName 
      + " portfolio and content and subscription details of it ?")
    .afterClosed()
    .subscribe(res => {
      if (res) {
        let deletePortObj:Portfolio = new Portfolio;
        deletePortObj.agentId = Number(sessionStorage.getItem('userId'));
        deletePortObj.id = this.portfolioObj.id;
        deletePortObj.portfolioName = this.portfolioObj.portfolioName;
        deletePortObj.portfolioValue = this.portfolioObj.portfolioName;
        this.equityService.deletePortfolio(deletePortObj).subscribe(data => {
          this.toast.success('Portfolio deleted Successfully!');
          sessionStorage.removeItem('privatePortSessionSel');
          // this.ngOnInit();
          this.router.navigate(['/equity',{}]);
         })
      }
    })
  }

  openExitRecoDialog(element:any,
    operationType: string): void {

    // let portfolio =  this.agentPortifolios.find((data: any) => data.portfolioName === this.privatePortSel); 
    // console.log("selected portfolio Name:" + portfolio.portfolioName + ",portfolio.id" +portfolio.id);
    if (element.transactionType == 'BUY') {
      element.transactionType = "SELL";
    } else if (element.transactionType == 'SELL') {
      element.transactionType = "BUY";
    }

    this.dialog.open(EquitydailogComponent, {
      data: {
        title: element.transactionType + ' ' + element.tradingsymbol,
        message: 'This is a sample dialog opened using Angular Material MatDialog!',
        portfolioId:this.portfolioObj.id,
        portfolioName: this.portfolioObj.portfolioName,
        entryPrice:element.triggerPrice,
        exitPrice: element.strikeData.last_price,
        returnPercent:element.returnPercent,
        createdDate:element.createdDate,
        operationType: operationType,
        orderId: element._id,
        transactionType: element.transactionType,
        tradingsymbol: element.tradingsymbol,
        orderStatus: element.orderStatus
      },
    }).afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

}
