import { Component,ViewChild } from '@angular/core';
import {LoginService } from '../../services/login.service';
import {UserService} from '../../services/user.service';
import { Router } from '@angular/router';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'] 
})
export class HeaderComponent {

  sessionUserId:any;
  isAdmin = false;
  loading = true;
  isLogged = false;

  constructor(private router:Router,private loginService:LoginService,public userService:UserService){
  }
  ngOnInit(){    
    console.log("sessionUserId from header:::",this.sessionUserId);
    this.loginService.isLogged.subscribe(logged => {
      this.isLogged = logged;
      if (logged) {       
          this.loading = false;
          this.sessionUserId = sessionStorage.getItem('userId');
      }
    });
    this.loginService.checkStatus();
  }

  hasRoleOf(role: string) {
    var userRoles = sessionStorage.getItem('userRoles')
    return userRoles != undefined && userRoles.includes(role);
  }  
  
  logoutUser(){
    this.loginService.signout().subscribe(() => {
        AuthInterceptor.accessToken = '';
        sessionStorage.clear();
        this.userService.isLoggedIn = false;
        this.userService.userEmail = '';
        this.userService.userName = '';
        this.router.navigate(['/login']);
        this.sessionUserId = null;
    });
}
}
