import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TradeService {
  constructor(private http:HttpClient){
  }
  

  searchStrikeData(searchkeyword:any,userId:any,pageId:any):Observable<any>{
    // console.log("TradeService:searchStrikeData agentID:"+searchkeyword);    
    return this.http.get<any>(environment.tradeServiceUrl+'/searchStrikeData/'+searchkeyword+'/'+userId+'/'+pageId);    
  }

  searchStrikeDataByExchange(searchkeyword:any,userId:any,pageId:any):Observable<any>{
    // console.log("TradeService:searchStrikeDataByExchange agentID:"+searchkeyword);
    return this.http.get<any>(environment.tradeServiceUrl+'/searchStrikeDataByExchange/'+searchkeyword+'/'+userId+'/'+pageId);    
  }

  saveFavoriteStrikes(favoriteObj:any):Observable<Object>{
    // console.log("Saving selected favorite::"+favoriteObj.strikeName);
    return this.http.post(environment.tradeServiceUrl+'/saveFavorites',favoriteObj);
  }

  deleteFavoriteStrikes(favoriteObj:any):Observable<Object>{
    // console.log("selected favorite::"+favoriteObj.strikeName);
    return this.http.post(environment.tradeServiceUrl+'/deleteFavorites',favoriteObj);
  }

  getFavoriteData(userId:any,pageId:any):Observable<any>{
    // console.log("getting getWatchList for agentID"+userId);
    return this.http.get<any>(environment.tradeServiceUrl+'/getFavoriteData/'+userId+'/'+pageId);
  }


  processEntryOrder(orderDetails:any):Observable<Object>{
    // console.log("http POST:processEntryOrder::"+orderDetails.strikeData.tradingsymbol);
    return this.http.post(environment.tradeServiceUrl+'/processEntryTrade',orderDetails);
  }

  processExitOrder(orderDetails:any):Observable<Object>{
    // console.log("http POST:processExitOrder::"+orderDetails.strikeData.tradingsymbol);
    return this.http.post(environment.tradeServiceUrl+'/processExitTrade',orderDetails);
  }


  cancelLimitOrder(orderDetails:any):Observable<Object>{
    // console.log("http POST:cancelLimitOrder::"+orderDetails.strikeData.tradingsymbol);
    return this.http.post(environment.tradeServiceUrl+'/cancelLimitOrder',orderDetails);
  }

  modifyLimitOrder(orderDetails:any):Observable<Object>{
    // console.log("http POST:modifyLimitOrder::"+orderDetails.strikeData.tradingsymbol);
    return this.http.post(environment.tradeServiceUrl+'/modifyLimitOrder',orderDetails);
  }

  getPositionDetails(agentId:any):Observable<Object>{
    // console.log("http POST:getPositionDetails::"+agentId);
    return this.http.post(environment.tradeServiceUrl+'/getPositionDetails',{"agentId":agentId});
  }

  getLimitOpenOrders(agentId:any):Observable<Object>{
    // console.log("http POST:getLimitOpenOrders::"+agentId);
    return this.http.post(environment.tradeServiceUrl+'/getLimitOpenOrders',{"agentId":agentId});
  }
  

  getCompletedOrders(agentId:any):Observable<Object>{
    // console.log("http POST:getCompletedOrders::"+agentId);
    return this.http.post(environment.tradeServiceUrl+'/getCompletedOrders',{"agentId":agentId});
  }

  getReportOrders(agentId:any,startDate:any,endDate:any):Observable<Object>{
    // console.log("http POST:getReportOrders::"+agentId);
    return this.http.post(environment.tradeServiceUrl+'/getReportOrders',
    {"agentId":agentId,"startDate":startDate,"endDate":endDate});
  }

 
}
