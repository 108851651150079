import { Component} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfile } from 'src/app/model/userprofile';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent {


  token!: string;
  userProfile: UserProfile = new UserProfile();
  resultSuccess!:any;
  resultFailed!:any;

  constructor(private route: ActivatedRoute, private router: Router, 
    private loginService: LoginService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.token = params['userToken'];
      }
    )
    this.verifyUserActivation();
    // this.resultSuccess = "testing local Congratulation, your account has been verified and activated...."
  }

  verifyUserActivation() {
    this.userProfile.resetToken = this.token
      this.loginService.verifyUser(this.userProfile).subscribe((res: any) => {
        if(res.success){
          this.resultSuccess = res.message;
        }else{
          this.resultFailed = res.message;
        }
      });
  }
}
