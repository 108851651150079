<div class="mat-elevation-z8">

    <mat-form-field class="example-form-field">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input
          [formGroup]="campaignOne"
          [rangePicker]="campaignOnePicker"
        >
          <input matStartDate placeholder="Start date" formControlName="start">
          <input matEndDate placeholder="End date" formControlName="end">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
      </mat-form-field>

      <button mat-button (click)="onSearchSubmit()">Submit</button>

    <mat-label>
        <mat-icon>search</mat-icon>
      </mat-label>  
      <mat-form-field appearance="outline" class="example-form-field">
        <mat-label>Search</mat-label>
        <input type="text" matInput (keyup)="applyFilter($event)" #input>
    </mat-form-field>
    
      <table mat-table [dataSource] = "dataSource"  matSort  #activeOrderSort="matSort" >
    
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdDate"> created Date </th>
          <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> OrderId </th>
          <td mat-cell *matCellDef="let element"> {{element._id}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="tradingsymbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tradingsymbol"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.strikeData.tradingsymbol}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="triggerQnty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Qnty"> Qnty </th>
          <td mat-cell *matCellDef="let element"> {{element.triggerQnty}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
    
        <ng-container matColumnDef="transactionType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by transactionType" > Type </th>
          <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
         <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Product" > ProductType </th>
            <td mat-cell *matCellDef="let element"> {{element.productType}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        <ng-container matColumnDef="orderVariety">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ProductType" > Order Variety </th>
            <td mat-cell *matCellDef="let element"> {{element.orderVariety}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
        <ng-container matColumnDef="orderStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email" > OrderStatus </th>
            <td mat-cell *matCellDef="let element"> {{element.orderStatus}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>   
          <ng-container matColumnDef="profitLoss">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by profitLoss" > P&L </th>
            <td mat-cell *matCellDef="let element"> {{element.totalProfitLoss}} </td>
            <td mat-footer-cell *matFooterCellDef>{{totalAmt | currency:'INR'}} </td>
          </ng-container>      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>               
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" #activeOrderpaginator
      showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
    </div>  
